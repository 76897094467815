<template>
<div id="admin">
    <div class="columns">
        <SideMenu
            class="column is-3"
        />
        <router-view
            class="column is-9"
        />
    </div>
</div>
</template>

<script>
import SideMenu from '@/components/admin/SideMenu.vue';
export default {
    components: {
        SideMenu
    },
    mounted: function() {
        this.$store.state.commenting.width = 0;
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    }
}
</script>

<style>
.columns {
    width: 100%;
}
</style>