<template>
    <div id="home">
        <HomeNotification />
        <Global />
        <div class="sales_trend_total_graph">
            <EmbeddedAnalytics
                v-if="group_id && report_id"
                class="kpibox-shadow"
                key="sales-tree"
                :group_id="group_id"
                :report_id="report_id"
                :pbi_filter="pbi_filter"
                :dimensions="report_dimensions"
            />
        </div>

        <div class="columns is-multiline m0">
            <template v-for="(box, index) in kpi_boxes">
                <div
                    class="column kpibox"
                    :class="{ 'is-4-desktop is-half-tablet': box.name === 'Better Company' }"
                    :key="index"
                    :id="box.location"
                >
                    <div v-if="kpi_render" class="kpibox-shadow one-hundred-height">
                        <div class="kpibox-header ikea-yellow columns">
                            <HomeIcons :icon="box.name" />
                            <div class="column kpibox-text" v-html="header_text_format(box.sub_title)"></div>
                        </div>

                        <div v-if="Object.keys(kpi_data).length">
                            <div v-for="(update_frequency, i) in frequency" :key="i">
                                <KPIBox
                                    v-if="kpi_data && kpi_data[box.name] && kpi_data[box.name][update_frequency]"
                                    :title="update_frequency"
                                    :box_name="box.name"
                                    :data="
                                        store
                                            ? kpi_data_market_filter(kpi_data[box.name][update_frequency])
                                            : kpi_data[box.name][update_frequency]
                                    "
                                />
                            </div>
                        </div>
                        <div v-else>No data found</div>
                    </div>
                </div>
            </template>
        </div>
        <div class="kpibox-shadow insights-box">
            <div v-if="comments_render && Object.keys(country_insights).length">
                <div class="text-left text-italics">
                    {{ $store.getters.selected_country_name }} - Retail Goal Letter KPI insights - FY25
                </div>
                <div v-if="country_insights['Introduction']">
                    <div v-for="(insight, i) in country_insights['Introduction']" :key="i" class="mt">
                        <div class="columns">
                            <div class="column text-left">
                                <b>{{ insight.title }}</b>
                            </div>
                            <div class="column text-right display-contents">
                                <CommentRatingButtons class="ml" :id="insight.comment_id" :current="insight.rating" />
                            </div>
                        </div>

                        <div v-html="insight.content" class="text-left mb"></div>
                    </div>
                </div>
                <div v-for="(box, i) in kpi_boxes" :key="i" class="mt">
                    <div v-if="country_insights[box.name] && country_insights[box.name].length">
                        <div class="insights-header ikea-yellow">
                            <InsightsIcons :icon="box.name" />
                            <div class="insights-subtitle text-small pt">
                                for
                                <b>{{ box.sub_title }}</b>
                            </div>
                        </div>
                        <div v-for="(insight, j) in country_insights[box.name]" :key="j" class="mt">
                            <div class="columns">
                                <div class="column text-left">
                                    <b>{{ insight.title }}</b>
                                </div>
                                <div class="column text-right display-contents">
                                    <CommentRatingButtons
                                        class="ml"
                                        :id="insight.comment_id"
                                        :current="insight.rating"
                                    />
                                </div>
                            </div>
                            <div v-html="insight.content" class="text-left mb"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>No Insights found for {{ $store.getters.selected_country_name }}</div>
        </div>
    </div>
</template>

<script>
import CommentRatingButtons from '@/components/ui/CommentRatingButtons.vue';
import LandingPageDropDown from '@/components/ui/LandingPageDropDown.vue';
import HomeNotification from '@/components/bespoke/home/HomeNotification.vue';
import HomeIcons from '@/components/bespoke/home/HomeTableIcons.vue';
import InsightsIcons from '@/components/bespoke/home/HomeInsightsIcons.vue';
import KPIBox from '@/components/bespoke/home/KPIBox.vue';
import value_creation_mapping from '@/assets/scripts/value-creation-mapping.js';
import RouterNames from '@/helper/router-names.js';
import Global from '@/views/global.vue';
import EmbeddedAnalytics from '@/components/visualisations/power-bi/EmbeddedAnalytics2.vue';

export default {
    components: {
        CommentRatingButtons,
        LandingPageDropDown,
        HomeNotification,
        HomeIcons,
        InsightsIcons,
        KPIBox,
        Global,
        EmbeddedAnalytics
    },
    props: {
        market: String
    },
    data: function () {
        return {
            kpi_data: null,
            kpi_render: false,
            comments_render: false,
            version_obj: {},
            frequency: ['Weekly', 'Monthly', 'Tertial', 'Yearly'],
            kpi_boxes: [
                {
                    name: 'Better Homes',
                    sub_title: 'Customers',
                    location: 'better-homes'
                },
                {
                    name: 'Better Planet',
                    sub_title: 'All',
                    location: 'better-planet'
                },
                {
                    name: 'Better Lives',
                    sub_title: 'People',
                    location: 'better-lives'
                },
                {
                    name: 'Better Company',
                    sub_title: 'Now and Future generations',
                    location: 'better-company'
                }
            ],
            responsibles: [],
            country_insights: null,
            report_id: null,
            group_id: null,
            route_id: null,
            report_section: '',
            pbi_filter: [],
            store: null,
            report_dimensions: null
        };
    },
    mounted: function () {
        this.$store.state.commenting.width = 0;
        this.load_kpi_data();
        this.load_comments();
        this.fetch_sales_tree_report();
    },
    destroyed: function () {
        this.$store.state.commenting.width = 4;
    },
    methods: {
        fetch_sales_tree_report() {
            // Get kpi details from Database
            this.$http
                .post('/kpidata/kpidataresponse', {
                    kpi_area: 'temp-frontend-sales-tree'
                })
                .then(succ => {
                    let res = succ.data;
                    if (res.PBIReport) {
                        this.kpi_response = res;

                        this.page_title = this.kpi_response.kpi_title;
                        this.group_id = this.kpi_response.PBIReport[0].groupId;
                        this.report_id = this.kpi_response.PBIReport[0].reportId;

                        if (this.kpi_response.pbi_filter.length > 0) {
                            this.pbi_filter = this.kpi_response.pbi_filter || null;
                        }
                        this.embed_key += 1;
                        this.response_data = this.kpi_response;

                        this.report_dimensions = {
                            width: `${window.innerWidth * (window.innerWidth < 768 ? 0.9 : 0.5)}px`,
                            height: `${window.innerHeight * (window.innerWidth < 768 ? 0.25 : 0.5)}px`
                        };
                    }
                })
                .catch(error => {
                    console.error('JSON Error', error);
                });
        },
        add_links: function () {
            this.kpi_boxes.forEach(value_creation_goal => {
                this.frequency.forEach(update_frequency => {
                    if (!this.kpi_data[value_creation_goal.name][update_frequency]) return;
                    this.kpi_data[value_creation_goal.name][update_frequency].forEach((kpi, index) => {
                        let found_kpi = this.find_in_vcg_mapping(kpi.name);
                        if (found_kpi) {
                            // this.kpi_data[value_creation_goal.name][update_frequency][index].link = found_kpi.path;
                            let route =
                                RouterNames.find(route => route.path === (found_kpi.location || found_kpi.path)) || {};
                            if (found_kpi.document) {
                                this.$http.get(`/version/${found_kpi.document}/by_country`).then(succ => {
                                    if (succ && succ.data) {
                                        this.kpi_data[value_creation_goal.name][update_frequency][index].version =
                                            this.$helper.format_fs_version(succ.data, route.name || null);
                                    } else {
                                        this.kpi_data[value_creation_goal.name][update_frequency][index].version =
                                            'N/A';
                                    }
                                }, this.$helper.error);
                                return;
                            }
                            if (route.name) {
                                this.$http.get(`/last-updated/${route.name}`).then(succ => {
                                    let d = succ.data;
                                    if (d.length === 0) {
                                        this.kpi_data[value_creation_goal.name][update_frequency][index].version =
                                            'N/A';
                                    } else {
                                        let { version } = succ.data.pop();
                                        this.kpi_data[value_creation_goal.name][update_frequency][index].version =
                                            version;
                                    }
                                }, this.$helper.error);
                            }
                        }
                    });
                });
            });
        },
        find_in_areas: function (area, selected_kpi) {
            if (area.name.toLowerCase().includes(selected_kpi.toLowerCase()) && area.path) {
                return { path: area.path, location: area.location, document: area.document };
            } else {
                if ('alt' in area) {
                    if (area.alt.find(kpi => kpi.name.toLowerCase().includes(selected_kpi.toLowerCase()))) {
                        return { path: area.path, location: area.location, document: area.document };
                    }
                }
                if ('areas' in area) {
                    for (let kpi_in_area of area.areas) {
                        let result = this.find_in_areas(kpi_in_area, selected_kpi);
                        if (result) return result;
                    }
                }
            }
        },
        find_in_vcg_mapping: function (kpi_name) {
            if (kpi_name === 'Services' || kpi_name === 'Physical' || kpi_name === 'Digital' || kpi_name === 'Support')
                kpi_name = 'Happy Customer';
            else if (kpi_name.toLowerCase().includes('zero waste')) kpi_name = 'Zero Waste';
            else if (kpi_name === 'HF' || kpi_name === 'Quantity') kpi_name = 'HFB';
            else if (kpi_name.toLowerCase().includes('operational result')) kpi_name = 'Operational Result';
            else if (kpi_name.toLowerCase().includes('choos') || kpi_name.toLowerCase().includes('worker'))
                kpi_name = 'Retention';
            else if (kpi_name.toLowerCase().includes('gross margin'))
                kpi_name = 'Operational Gross Margin Forecast vs Goal';
            else kpi_name = kpi_name.replace(/[^0-9a-z\s]/gi, '');

            let kpi = null;
            for (let i = 0; i < 4; i++) {
                kpi = this.find_in_areas(value_creation_mapping[i], kpi_name);
                if (kpi) break;
            }
            return kpi;
        },
        load_kpi_data: function () {
            this.kpi_render = false;
            this.kpi_data = {};
            this.$http.post('/landing-page/vgc-landing', { market: this.$store.getters.market }).then(succ => {
                if (!succ.data || !Object.keys(succ.data).length) {
                    this.kpi_data = this.reset_kpi_data();
                } else {
                    this.kpi_data = succ.data;
                    //this.add_links();
                }
                this.kpi_render = true;
            }, this.$helper.error);
        },
        load_market_data: function () {
            this.kpi_render = false;
            this.kpi_data = {};
            this.$http.post('/landing-page/market-level', { market: this.$store.getters.market }).then(succ => {
                if (!succ.data || !Object.keys(succ.data).length) {
                    this.kpi_data = this.reset_kpi_data();
                } else {
                    this.kpi_data = succ.data;
                    //this.add_links();
                }
                this.kpi_render = true;
            }, this.$helper.error);
        },
        header_text_format: function (text) {
            return `<p>for <b>${text}</b>`;
        },
        load_comments: function () {
            this.comments_render = false;
            this.country_insights = {};
            let intro = [
                {
                    name: 'Introduction',
                    location: 'introduction'
                }
            ];
            let counter = 0;

            intro.concat(this.kpi_boxes).forEach(area => {
                let temp_to_load_obj = {
                    filters: {
                        order_by: null,
                        search: null
                    },
                    kpi: 'country-insights',
                    kpi_area: area.location,
                    market_area: this.$store.getters.market,
                    status: 'LIVE',
                    store_area: null
                };
                this.$chttp.post('/comments/load', temp_to_load_obj).then(
                    succ => {
                        let d = succ.data;
                        counter++;
                        if (d && d.length) {
                            this.country_insights[area.name] = d;
                        }

                        if (counter === intro.concat(this.kpi_boxes).length) {
                            this.comments_render = true;
                        }
                    },
                    error => {}
                );
                this.$http
                    .post('/comments/insights-responsibles/search', {
                        area: 'ALL'
                    })
                    .then(succ => {
                        let d = succ.data;
                        if (!succ || !d) {
                            this.responsibles = [];
                            return;
                        }
                        this.responsibles = d;
                    }, this.$helper.error);
            });
        },
        update_store: function (store) {
            this.store = store;
            // this.store ? this.load_market_data() : this.load_kpi_data();
            this.load_kpi_data();
        },
        kpi_data_market_filter: function (kpi_box_data_unfiltered) {
            let temp_arr = [];
            kpi_box_data_unfiltered.map(data_row => {
                let temp = {
                    data_keys: data_row.data_keys,
                    headers: data_row.headers,
                    icon_values: {},
                    link: data_row.link,
                    measures: {},
                    name: data_row.name,
                    position_value: data_row.position_value,
                    stores_list: data_row.stores_list,
                    version: data_row.version
                };
                if (!temp.stores_list || !temp.stores_list.length) {
                    temp_arr.push(temp);
                    return;
                }
                let index_of_store = temp.stores_list.indexOf(this.store);
                if (index_of_store === -1) {
                    temp_arr.push(temp);
                    return;
                }
                temp.icon_values = data_row.icon_values[index_of_store];
                temp.measures = data_row.measures[index_of_store];

                temp_arr.push(temp);
            });
            return temp_arr;
        }
    },
    computed: {
        stores: function () {
            if (this.$store.getters.stores.values.length) {
                return this.$store.getters.stores;
            } else {
                return {
                    all: [],
                    options: [],
                    values: []
                };
            }
        }
    },
    watch: {
        '$store.getters.market': function (newMarket, oldMarket) {
            this.store = null;
            this.load_kpi_data();
            this.load_comments();
        }
    }
};
</script>

<style>
#home {
    background-color: #eee;
    display: block;
    height: 100%;
    min-height: calc(100vh - 3.5rem);
}
.column is-3-desktop {
    flex: 1 !important;
}
.dropdown-background {
    width: 30rem;
    height: 3.25rem;
    margin: auto;
    background: rgb(0, 81, 186);
    transform: perspective(1rem) rotateX(-1deg);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    box-shadow: 3px 3px 9px #4a4a4a9e;
}
.dropdown-area {
    padding-top: 0.5rem;
    transform: perspective(2rem) rotateX(2deg);
}

.insights-box {
    width: calc(100vw - 1.5rem);
    margin-left: 0.75rem;
    margin-right: 0.75rem !important;
    margin-top: 1rem;
    padding: 1rem;
}

.insights-header {
    height: 3rem;
    margin-left: 2rem;
}
.insights-subtitle {
    color: black;
    text-align: left;
    margin-left: 2.25rem;
    padding-top: 0.75rem;
}
.kpibox-header {
    color: black !important;
    margin: 0 !important;
    min-height: 4rem;
}
.kpibox-shadow {
    background-color: #ffffff;
    box-shadow: 3px 3px 9px #4a4a4a9e;
}
.kpibox-text {
    display: flex !important;
    margin: auto;
    align-items: center;
}
.kpibox {
    padding-top: 1.5rem !important;
}
.text-italics {
    font-size: 1.3rem;
}

.sales_trend_total_graph {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 1rem;
}
</style>
