var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdown is-hoverable navbar-item" }, [
    _c("div", { staticClass: "dropdown-trigger hidden-on-mobile" }, [
      _vm._v(" Extra "),
    ]),
    _c("div", { staticClass: "dropdown-menu", attrs: { role: "menu" } }, [
      _c("div", { staticClass: "dropdown-content" }, [
        _c(
          "a",
          {
            staticClass: "dropdown-item",
            on: {
              click: function ($event) {
                return _vm.route_to(`/country-overview/country`)
              },
            },
          },
          [_c("div", [_vm._v(" Country Overview ")])]
        ),
        _vm.$store.getters.check_access(["country-insights", "*", "r"])
          ? _c(
              "a",
              {
                staticClass: "dropdown-item",
                on: {
                  click: function ($event) {
                    return _vm.route_to(
                      `/country-insights/introduction/${_vm.$store.state.selected.market}`
                    )
                  },
                },
              },
              [_c("div", [_vm._v(" Insights ")])]
            )
          : _vm._e(),
        false &&
        _vm.$store.getters.check_access(["executive-summary", "*", "r"])
          ? _c(
              "a",
              {
                staticClass: "dropdown-item",
                on: {
                  click: function ($event) {
                    return _vm.route_to(`/executive-summary`)
                  },
                },
              },
              [_c("div", [_vm._v(" Executive Summary ")])]
            )
          : _vm._e(),
        false && _vm.$store.state.global.is_ift_user
          ? _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "https://ingka.flight-tower.com/" },
              },
              [_vm._v(" Ingka Flight Tower ")]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }