<template>
  <div v-if="kpi_area" :key="componentKey">
    <!-- <PageDetail :page_details="response_data" :location="this.custom_location"/> -->
  
    <Title
        :title="response_data.kpi_title"
        :location="this.$route.fullPath"
        from_pbi
    />

    <div v-if="disabled_areas.includes(kpi_area)">
      <img class="assembled" src="/assets/images/presentation/being-assembled.png" />
    </div>
    <div v-else>
      <div class="options-bar">
        <Buttons
          :buttons="generate_buttons()"
          class="mt1p5 is-pulled-left"
          :clickHandler="setReportSection"
        />
      </div>

      <EmbeddedAnalytics
        :key="embed_key"
        :group_id="group_id"
        :report_id="report_id"
        :report_section="report_section"
        :pbi_filter="pbi_filter"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import PageDetail from '@/views/PageDetail.vue';
import Title from '@/components/ui/Title.vue';
import EmbeddedAnalytics from '@/components/visualisations/power-bi/EmbeddedAnalytics.vue';

export default {
  components: {
    Buttons,
    DropDown,
    EmbeddedAnalytics,
    Title,
    // PageDetail,
  },
  data() {
    return {
      excluded_locations: [
        'by-market',
        'detail',
        'summary',
        'top-8',
        'tree',
        'trend'
      ],
      kpi_name: null,
      kpi_area: null,
      kpi_response: null,
      custom_location: null,
      page_title: null,
      show_kpi_defintion: false,
      kpi_definition: false,
      rating: null,
      group_id: null,
      route_id: null,
      report_section: "",
      pbi_filter: [],
      page_country_filter: "no",
      disabled_areas: ["climate-footprint"],
      is_loaded: {
        kpi_definition: false
      },
      embed_key: 0,
      response_data: "",
      report_id: null
    };
  },
  computed: {
    componentKey() {
      return this.$route.fullPath;
    },
    filteredParams() {
      if (!this.kpi_response) return {};
      const excludeParams = ['kpi_title'];
      const filtered = {};
      for (const [key, value] of Object.entries(this.kpi_response)) {
        if (Array.isArray(value) || value === null || excludeParams.includes(key)) {
          continue;
        }
        filtered[key] = value;
      }
      return filtered;
    }
  },
  mounted() {
    this.$store.commit('set_routing', false);
    this.fetchData();
  },
  methods: {
    fetchData() {
      const fullpath = this.$route.fullPath;
      const pathWithoutQuery = fullpath.split('?')[0];  //if any query in the url rejecting it
      const splitpath = pathWithoutQuery.split('/').filter(Boolean);
      this.kpi_name = splitpath[0];
      // this.kpi_area = splitpath[1];
      this.kpi_area = splitpath[splitpath.length - 1];    
      this.custom_location = {
        kpi: this.kpi_name,
        kpi_area: this.kpi_area,
        market_area: null,
        store_area: null,
        KPIerrmsg:0
      };

        // Get kpi details from Database
      this.$http
        .post("/kpidata/kpidataresponse", {
          kpi_area: this.custom_location.kpi_area,
        })
        .then((succ) => {
          let res = succ.data;
          if(res.PBIReport){
          this.kpi_response = res;
          }else if(succ.data.message){
            alert(succ.data.message + " redirecting to previous page.");
           this.$router.go(-1);
          }
        
        // });
      // axios.get('/dummykpires.json')
      //   .then(response => {
      //     this.kpi_response = response.data[this.custom_location.kpi_area];
          this.page_title = this.kpi_response.kpi_title;
          this.group_id = this.kpi_response.PBIReport[0].groupId;
          this.report_id = this.kpi_response.PBIReport[0].reportId;

          if (this.kpi_response.Buttons && this.kpi_response.Buttons.length > 0) {
            let first_btn_rprtsection = this.kpi_response.Buttons[0].report_section;
            this.report_section = first_btn_rprtsection;
            this.pbi_filter = this.kpi_response.Buttons[0].pbi_filter || null;
          }
             
          if (!this.kpi_response.Buttons.length && this.kpi_response.pbi_filter.length > 0) {
             this.pbi_filter = this.kpi_response.pbi_filter || null;  
          }          
          this.embed_key += 1;
          this.response_data = this.kpi_response;
        })
        .catch(error => {
          console.error("JSON Error", error);
        });
    },   
    setReportSection(reportSection, country_filter, pbi_filter) {
      if (reportSection) this.report_section = reportSection;
      if (country_filter) this.page_country_filter = country_filter;
      if (pbi_filter) this.pbi_filter = pbi_filter;
      this.embed_key += 1;
    },
    generate_buttons() {
      if (this.kpi_response && this.kpi_response.Buttons && this.kpi_response.Buttons.length > 0) {
        return this.kpi_response.Buttons;
      } else {
        return [];
      }
    },

  },
  watch: {
    '$route'(to, from) {
      this.fetchData();
    }
  },
}
</script>

<style scoped>
.options-bar {
  margin-top: 0rem;
  padding-bottom: 0rem;
  align-items: flex-start; 
  width: 100%;
}

.options-bar .mt1p5 {
  margin-top: 0rem;
}
</style>