var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "home" } },
    [
      _c("HomeNotification"),
      _c("Global"),
      _c(
        "div",
        { staticClass: "sales_trend_total_graph" },
        [
          _vm.group_id && _vm.report_id
            ? _c("EmbeddedAnalytics", {
                key: "sales-tree",
                staticClass: "kpibox-shadow",
                attrs: {
                  group_id: _vm.group_id,
                  report_id: _vm.report_id,
                  pbi_filter: _vm.pbi_filter,
                  dimensions: _vm.report_dimensions,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "columns is-multiline m0" },
        [
          _vm._l(_vm.kpi_boxes, function (box, index) {
            return [
              _c(
                "div",
                {
                  key: index,
                  staticClass: "column kpibox",
                  class: {
                    "is-4-desktop is-half-tablet":
                      box.name === "Better Company",
                  },
                  attrs: { id: box.location },
                },
                [
                  _vm.kpi_render
                    ? _c(
                        "div",
                        { staticClass: "kpibox-shadow one-hundred-height" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "kpibox-header ikea-yellow columns",
                            },
                            [
                              _c("HomeIcons", { attrs: { icon: box.name } }),
                              _c("div", {
                                staticClass: "column kpibox-text",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.header_text_format(box.sub_title)
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                          Object.keys(_vm.kpi_data).length
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.frequency,
                                  function (update_frequency, i) {
                                    return _c(
                                      "div",
                                      { key: i },
                                      [
                                        _vm.kpi_data &&
                                        _vm.kpi_data[box.name] &&
                                        _vm.kpi_data[box.name][update_frequency]
                                          ? _c("KPIBox", {
                                              attrs: {
                                                title: update_frequency,
                                                box_name: box.name,
                                                data: _vm.store
                                                  ? _vm.kpi_data_market_filter(
                                                      _vm.kpi_data[box.name][
                                                        update_frequency
                                                      ]
                                                    )
                                                  : _vm.kpi_data[box.name][
                                                      update_frequency
                                                    ],
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _c("div", [_vm._v("No data found")]),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _c("div", { staticClass: "kpibox-shadow insights-box" }, [
        _vm.comments_render && Object.keys(_vm.country_insights).length
          ? _c(
              "div",
              [
                _c("div", { staticClass: "text-left text-italics" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$store.getters.selected_country_name) +
                      " - Retail Goal Letter KPI insights - FY25 "
                  ),
                ]),
                _vm.country_insights["Introduction"]
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.country_insights["Introduction"],
                        function (insight, i) {
                          return _c("div", { key: i, staticClass: "mt" }, [
                            _c("div", { staticClass: "columns" }, [
                              _c("div", { staticClass: "column text-left" }, [
                                _c("b", [_vm._v(_vm._s(insight.title))]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "column text-right display-contents",
                                },
                                [
                                  _c("CommentRatingButtons", {
                                    staticClass: "ml",
                                    attrs: {
                                      id: insight.comment_id,
                                      current: insight.rating,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", {
                              staticClass: "text-left mb",
                              domProps: { innerHTML: _vm._s(insight.content) },
                            }),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _vm._l(_vm.kpi_boxes, function (box, i) {
                  return _c("div", { key: i, staticClass: "mt" }, [
                    _vm.country_insights[box.name] &&
                    _vm.country_insights[box.name].length
                      ? _c(
                          "div",
                          [
                            _c(
                              "div",
                              { staticClass: "insights-header ikea-yellow" },
                              [
                                _c("InsightsIcons", {
                                  attrs: { icon: box.name },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "insights-subtitle text-small pt",
                                  },
                                  [
                                    _vm._v(" for "),
                                    _c("b", [_vm._v(_vm._s(box.sub_title))]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.country_insights[box.name],
                              function (insight, j) {
                                return _c(
                                  "div",
                                  { key: j, staticClass: "mt" },
                                  [
                                    _c("div", { staticClass: "columns" }, [
                                      _c(
                                        "div",
                                        { staticClass: "column text-left" },
                                        [
                                          _c("b", [
                                            _vm._v(_vm._s(insight.title)),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "column text-right display-contents",
                                        },
                                        [
                                          _c("CommentRatingButtons", {
                                            staticClass: "ml",
                                            attrs: {
                                              id: insight.comment_id,
                                              current: insight.rating,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("div", {
                                      staticClass: "text-left mb",
                                      domProps: {
                                        innerHTML: _vm._s(insight.content),
                                      },
                                    }),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ])
                }),
              ],
              2
            )
          : _c("div", [
              _vm._v(
                "No Insights found for " +
                  _vm._s(_vm.$store.getters.selected_country_name)
              ),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }