<template>
<div id="app">
	<PresentationMode v-if="presentation_mode > 0" />
	<div v-else>
		<MenuBar v-if="!$store.state.global.bot_mode" />
		<AccessRequest />
		<BrowserNotice />

		<div class="columns is-gapless">
			<div class="column"
				
				:class="`is-${12 - Number($store.state.commenting.width)}`"
			>
				<Spinner v-show="$store.state.loading !== 0" />
				<div :class="{'max-width-content-wrapper': limit_width}">
					<router-view />
					<NPSForm />
				</div>
			</div>

			<div class="column"
				v-if="!$store.state.global.bot_mode && $store.state.commenting.width !== 0"
				:class="`is-${$store.state.commenting.width}`"
			>
				<Comments/>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import AccessRequest from '@/components/bespoke/home/AccessRequest.vue';
import BrowserNotice from '@/components/bespoke/home/BrowserNotice.vue';
import Comments from '@/views/Comments.vue';
import NPSForm from '@/components/nps/NPSForm.vue';
import KPIroutes from '@/assets/scripts/kpi-data-mapping.js';

import MenuBar from '@/components/ui/MenuBar.vue';
import Spinner from '@/components/ui/Spinner.vue';

import PresentationMode from '@/components/presentation-mode/PresentationMode.vue';

export default {
	components: {
		AccessRequest,
		BrowserNotice,
		Comments,
		MenuBar,
		NPSForm,
		PresentationMode,
		Spinner,
	},
	data: function() {
		return {
			delta: 200,
			fixed_width_routes: [],
			timeout: false,
		}
	},
	mounted: function() {
		this.$store.dispatch('get_countries');
		this.$store.dispatch('get_hfbs');
		this.$store.dispatch('get_stores');

		/* Check if they've been redirected */
		let last_visited = localStorage.last_visited;
		if (last_visited) {
			last_visited = JSON.parse(last_visited);
			localStorage.removeItem('last_visited');
			if (this.$route.path !== last_visited.path) {
				this.$router.push(last_visited.path);
			}
		}

		this.find_fixed_width_routes();
	},
	methods: {
		find_fixed_width_routes: function() {
			this.fixed_width_routes = [];
			KPIroutes.forEach(route => {
				this.fixed_width_routes.push(route.path.slice(1));
			});
		},
		resize: function() {
			let initial_size = {
				width: window.innerWidth,
				height: window.innerHeight
			}
			let self = this;
			setTimeout(function() {
				if (initial_size.width === window.innerWidth && initial_size.height === window.innerHeight) {
					self.$store.commit('update_app_size', {
						width: window.innerWidth,
						height: window.innerHeight
					});
				}
			}, 500);
		},
	},
	computed: {
		limit_width: function() {
			return this.fixed_width_routes.includes(this.$route.path.split('/')[1]);
		},
		presentation_mode: function() {
			return this.$store.getters.presentation_mode;
		}
	}
}
</script>

<style>
#app {
	font-family: 'NotoIKEALatin-Regular';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding-top: 3.5rem;
	text-align: center;
	color: #2c3e50;
}

html {
	overflow-y: scroll !important;
}

#nav {
  	padding: 30px;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
}

.column {
  	transition: all 0s ease-in !important;
}

.half-height {
  	height: 50vh;
}

.max-width-content-wrapper {
	max-width: 1600px;
}

.options-bar {
	display: inline-flex;
	margin-top: -0.5rem;
	padding: 0rem 1rem 1rem 1rem;
	width: 100%;
}

.options-bar-sub {
  	text-align: left;
}

.options-bar-sub p {
  	padding-top: 0.3rem;
}
</style>
