import router from './../router';
import RouterNames from './router-names.js';
import MenuRoutes from './../assets/scripts/kpi-data-mapping.js';

const current_FY = (digits = 2) => {
    digits = parseInt(digits);
    if (digits !== 4 && digits !== 2) {
        console.error(`The current_FY helper func can only take parameter values of 2 or 4.\nProvided param = ${digits}`);
        return null;
    }

    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();

    let FY = month > 7 ? year : year - 1;
    if (digits === 2) FY = FY - 2000;
    return FY;
}

const date_formatting = (date, type) => {
    let formatted_date;
    let split_date = date.split('.')[0];
    split_date = split_date.split('T');
    split_date[1] = split_date[1].split('').slice(0, 5).join().replace(/,/g, '');
    switch (type) {
        case 'DATE':
            formatted_date = split_date[0];
        break;
        case 'TIME':
            formatted_date = split_date[1];
        break;
        case 'DATETIME':
            formatted_date = `${split_date[0]} ${split_date[1]}`
        break;
    }
    return formatted_date;
};
const error = e => console.log(e);

const format_fs_version = (raw_version, title = null) => {
    const digits_regex = /^\d+$/;
    if (!digits_regex.test(raw_version)) return 'N/A';

    //Awful exception to be removed ASAP when weekly kpis are denoted in database
    const weekly_kpis = ['offered lead time', 'sales and quantity', 'cumulative checkout abandonment rate', 'add to cart rate'];
    const is_weekly = !!title && weekly_kpis.includes(title.toLowerCase());

    let year = parseInt(raw_version.toString().slice(2,4), 10);
    let month_or_week = parseInt(raw_version.toString().slice(4,6), 10);
    if (month_or_week > (is_weekly ? 35 : 8)) year++;

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    return `FY${year} ${is_weekly ? `Week ${month_or_week}` : months[month_or_week-1] ? months[month_or_week-1] :''}`;
}

const header_replace = (headers, replacements) => {
    headers[0].forEach(header => {
        const first_key = Object.keys(header).shift();
        const last_key = Object.keys(header).pop();
        if (replacements[last_key]) {
            header[replacements[last_key]] = 1;
            delete header[first_key];
        }
    });
};
const page_name = router => {
    // let route = RouterNames.filter(route => route.path === router.history.current.matched[0].path);

    //above is not working for the pbi integrated kpi 
    let route = RouterNames.filter(route => route.path === router.history.current.path);
    if (route && route[0] && route[0].name){
        return route[0].name;
    } else {
        return null;
    }
};
const page_name_lookup = path => {
    const lookup = areas => {
        for (let area of areas) {
            if (area.path === path) {
                return area.name;
            }
            if (area.areas) {
                let resp = lookup(area.areas);
                if (resp) return resp;
            }
        }
    }

    return lookup(MenuRoutes);
};

const location = () => {
    let route = router.currentRoute.path;
    let location_keys = ['kpi', 'kpi_area', 'market_area', 'store_area'];
    let location = {};
    if (route[0] === '/') {
        route = route.slice(1, route.length);
    }
    route = route.split('/');

    if (route.length > 4) {
        for (let i = 4; i < route.length; i++) {
            route[3] += `/${route[i]}`;
        }
    }

    location_keys.map((loc, index) => {
        if (route[index]) {
            location[loc] = market_check(route[index]);
        } else {
            location[loc] = null;
        }
    });
    return location;
};

const market_check = market => market === 'null' ? null : market;
const maybe = (arg, fallback = 0) => (arg === undefined || arg === null || arg === 0) ? fallback : arg;
const meta_formatting = (keys, raw_meta) => {
    let meta_data = {
        icon_settings: {},
        decimal_settings: {}
    };
    let alt_headers = {};
    raw_meta.label.forEach(label => {
        const header = Object.keys(label)[0];
        if (label[header]) {
            alt_headers[header] =  label[header];
        }
    });

    const headers = keys.map(c =>
        ({[c in alt_headers ? alt_headers[c] : c]: 1})
    );

    raw_meta.label.forEach((column, i) => {
        const header = Object.keys(column)[0];
        const alt_header = header in alt_headers ? alt_headers[header] : header;
        let temp = {}
        const value = raw_meta.comparison[i][header];
        if (value) {
            temp = {
                comparison: value in alt_headers ? alt_headers[value] : value,
                icon: raw_meta.icon[i][header]
            }
        }
        const fix = raw_meta.fix[i][header];
        if (fix) {
            temp.fix = { post: fix === 'percentage' ? '%' : fix }
        }
        meta_data.icon_settings[alt_header] = temp;
        if (!raw_meta.decimal_places[i][header]) return;
        meta_data.decimal_settings[alt_header] = {precision: raw_meta.decimal_places[i][header]};
    });

    return { headers, meta_data }; 
};

/**
 * @param {String | Number} value - The value to be rounded - if not a number as defined by num_regex, returns value
 * @param {BigInt} precision (Optional) - The number of decimal places to round to (defaults to zero) (null -> no rounding)
 * @param {String} thousands_separation (Optional) - Character to separate thousands. If falsey (default) no thousands separation
 * @returns {String} - value rounded to the specified precision and with specidied thousands separation for value >= 1000
 * If value is falsey or a string that cannot be rounded (fails num_regex check) the input value will be returned.
 */
const round = (value, precision = 0, thousands_separation = '') => {
    const valid_input = val => {
        const num_regex = new RegExp('^-?([0-9]*[.])?[0-9]+$');
        if ((!val && val !== 0) || (typeof(val) === 'string' && !num_regex.test(val))) return false;
        if (!Number.isInteger(precision) && precision !== false || precision < -10 || precision > 10) {
            if (precision !== null) throw new Error('Invalid precision');
        }
        return true;
    };

    const format_decimal_places = val => {
        if (precision === null) return value;
        if (precision >= 0) return parseFloat(val).toFixed(precision);
        else {
            const magnitude = Math.pow(10, -precision);
            return Math.round(parseFloat(val) / magnitude) * magnitude;
        }
    };

    const format_thousands_separation = (val) => {
        if (!thousands_separation || parseFloat(val) < 1000 && parseFloat(val) > -1000) return val;
        const is_negative = parseFloat(val) < 0;
        val = val.toString();
        if (is_negative) val = val.slice(1);

        let [integer_part, decimal_part] = val.split('.');
        let new_val = [];
        while (integer_part.length > 3) {
            new_val.unshift(integer_part.slice(-3));
            new_val.unshift(thousands_separation);
            integer_part = integer_part.slice(0,-3);
        }
        new_val.unshift(integer_part);
        if (is_negative) new_val.unshift('-');
        if (decimal_part) new_val.push('.', decimal_part);

        return new_val.join('');
    };
    
    if (!valid_input(value)) return value;
    if (precision !== false) value = format_decimal_places(value);
    value = format_thousands_separation(value);
    return value.toString();
};

/**
 * Seperates out the link thats passed to the function by the / then asigns the first for a key
 * @param {String} value
 * @returns {Object}
 */
 const seperate_link_into_areas = path => {
    let keys = ["kpi", "kpi_area", "market_area", "store_area"];
    path = path.split('/');
    path.shift();
    let temp = {};
    keys.map((key, index) => {
        if (index < path.length) {
            temp[key] = path[index];
        } else {
            temp[key] = null;
        }
    });
    return temp;
}

/**
 * Checks if given value (string or number) is an integer and returns true or false accordingly
 * @param {String|Number} value
 * @returns {Boolean}
 */
const isInt = value => {
    return !isNaN(value) && 
        parseInt(Number(value)) == value && 
        !isNaN(parseInt(value, 10));
}

/**
 * Checks if given value is truthy OR 0
 * @param {*} value 
 * @returns {Boolean}
 */
const is_truthy_or_zero = value => {
    return (!!value || value == 0); 
}

/**
 * @returns { parse(), parse_by_hfb(), single_icon_conversion() }
 */
const table = {
    /**
     * Iterates over the list of rows that come back from the API to produce
     * the list of headers and rows ready to insert into the table
     * 
     * @param {object} data Succ.data from HTTP request
     * @returns { headers, rows } Headers and rows for the basic (single row header) table
     */
    parse: data => {
        if (!data || !data.length) return { headers: null, rows: null };
        let keys = Object.keys(data[0].measures);
        let headers = [['Country', ...keys].map(c => ({ [c]: 1}))];
        let rows = data.map(row => {
            let temp = [];
            keys.map(key =>
                temp.push(maybe(row.measures[key]))
            );
            return [row.caption, ...temp];
        });
        return { headers, rows };
    },
    /**
     * Iterates over the list of rows that come back from the API to produce
     * the list of headers and rows ready to insert into the table
     * Specifically for By HFB/By Product tables which have deeper nested measures
     * 
     * @param {array} data Succ.data from HTTP request
     * @param {string} first_header_name Override if you want the headers[0][0] to be something other than 'Country'
     * @returns { headers, rows } Headers and rows for the basic (single row header) table
     */
    parse_by_hfb: (data, first_header_name = 'Country') => {
        if (!data || !data.length) return { headers: null, rows: null };
        let measures = data[0].measures;
        let keys = Object.keys(measures[Object.keys(measures)[0]]);
        let headers = [[first_header_name, ...keys].map(c => ({[c]: 1}) )];
        let rows = [];
        Object.keys(measures).forEach(key => {
            let temp = [];
            temp.push(key);
            keys.map(k => {
                temp.push(maybe(measures[key][k]));
            });
            rows.push(temp);
        });
        return { headers, rows };
    },
    single_icon_conversion: (rows, index, ignore) => {
        rows.forEach((row, r) => { // Change cell values for icons
            let v = row[index];
            rows[r][index] = v == 0 ? 'equal' :
                v == -1 ? 'down' :
                v == 1 ? 'up' :
                v;
        });
        return rows;
    },
    apply_sort_settings: (rows, sort_value, index = 0) => {
        rows.sort((a, b) => {
            let A = a[index].display_value;
            let B = b[index].display_value;
            if (sort_value === 1) {
                if (A < B) return 1;
                if (A > B) return -1;
            } else {
                if (A < B) return -1;
                if (A > B) return 1;
            }
            

            return 0;
        });

        let static_bottom_rows = ['Total Retail Countries', 'Total', 'Total HFB', 'Total Revenue', 'Global'];
        static_bottom_rows.forEach(key => {
            let match_index = rows.findIndex(row => row[0].display_value === key);
            if (match_index >= 0) {
                rows.push(rows[match_index]);
                rows.splice(match_index, 1);
            }
        });
        return rows;
    }
}

export default {
    current_FY,
    date_formatting,
    error,
    format_fs_version,
    header_replace,
    page_name,
    page_name_lookup,
    location,
    market_check,
    maybe,
    meta_formatting,
    round,
    seperate_link_into_areas,
    isInt,
    is_truthy_or_zero,
    table
};
