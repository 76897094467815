var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex" }, [
    !_vm.$store.state.global.legacy_menu
      ? _c(
          "div",
          { staticClass: "dropdown is-hoverable navbar-item main-dropdown" },
          [
            _c(
              "a",
              {
                staticClass:
                  "navbar-link dropdown-trigger text-bold align-right",
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$store.getters.selected_country_name) + " "
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "dropdown-menu",
                attrs: { id: "dropdown-menu", role: "menu" },
              },
              [
                _c(
                  "div",
                  { staticClass: "dropdown-content" },
                  _vm._l(_vm.displayed_countries, function (country_column, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "columns is-gapless" },
                      _vm._l(country_column, function (country, j) {
                        return _c(
                          "div",
                          {
                            key: j,
                            staticClass: "dropdown-item column",
                            class: `
                            ${
                              country &&
                              country.caption ===
                                _vm.$store.getters.selected_country_name
                                ? "selected_country "
                                : ""
                            }
                            ${!country ? "no-hover" : ""}
                        `,
                            on: {
                              click: function ($event) {
                                country ? _vm.update_filter(country) : ""
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "columns is-gapless" }, [
                              _c("div", { staticClass: "column is-3" }, [
                                !country
                                  ? _c("div")
                                  : country.code
                                  ? _c("div", {
                                      staticClass: "dropdown-flag mobile-flag",
                                      class: country
                                        ? _vm.render_flag(country)
                                        : "",
                                    })
                                  : _c(
                                      "i",
                                      {
                                        staticClass:
                                          "dropdown-globe globe-outline",
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-globe-europe",
                                        }),
                                      ]
                                    ),
                              ]),
                              _c("div", { staticClass: "column is-9" }, [
                                _c("div", { staticClass: "pap5 mobile-text" }, [
                                  _vm._v(
                                    _vm._s(country ? country.caption : "")
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  }),
                  0
                ),
              ]
            ),
          ]
        )
      : _c(
          "a",
          { staticClass: "navbar-link", on: { click: _vm.select_country } },
          [_vm._v(" Select Country ")]
        ),
    _vm.$store.getters.flag
      ? _c("div", { staticClass: "image-placement hidden-on-mobile" }, [
          _c("div", {
            staticClass: "menu-flag",
            class: _vm.render_flag_market_only(_vm.$store.getters.flag),
          }),
        ])
      : _c("div", { staticClass: "image-placement hidden-on-mobile" }, [
          _vm._m(0),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "globe-outline inline-block main-global" },
      [_c("i", { staticClass: "fas fa-globe-europe" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }