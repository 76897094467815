export default[
    {
        name: "Better Homes",
        path: "/better-homes",
        areas: [
            {
                name: "Happy Customer",
                path: "/customer/happy-customer",
                color: "#8eec8e",
            },
            {
                name: "Brand Trust",
                path: "/brand/trust",
                color: "#8eec8e",
            },
            {
                name: "Affordability",
                path: "/three-roads/affordability",
                color: "#8eec8e",
                alt: [
                    {
                        name: "Furniture that fits my budget",
                    },
                ],
            },
            {
                name: "Brand Desire",
                path: "/brand/desire",
                color: "#8eec8e",
            },
            {
                name: "Penetration",
                path: "/three-roads/penetration",
                color: "#8eec8e",
            },
            {
                name: "Positive Impact",
                path: "/three-roads/positive-impact",
                color: "#8eec8e",
                alt: [
                    {
                        name: "Positive Impact Perception",
                    },
                ],
            },
            {
                name: "Perfect Order Performance",
                path: "/business/perfect-order-performance",
                color: "#8eec8e",
            },
        ],
    },
    {
        name: "Better Planet",
        path: "/better-planet",
        areas: [
            {
                name: "Climate footprint",
                color: "#8eec8e",
                areas: [
                    {
                        name: "Climate Footprint - Own Operations",
                        path: "/sustainability/climate-footprint",
                        color: "#8eec8e",
                        alt: [
                            {
                                name: "Climate footprint of unit operations",
                            },
                        ],
                    },
                    {
                        name: "Climate Footprint - Food ingredients",
                        path: "/sustainability/climate-footprint-food-ingredients",
                        document: "FoodIngredients",
                        color: "#8eec8e",
                    },
                ],
            },
            {
                name: "Waste",
                path: "/sustainability/waste",
                color: "#8eec8e",
            },
            {
                name: "Zero Emissions Deliveries",
                path: "/sustainability/zero-emissions",
                document: "ZeroEmissions",
                color: "#8eec8e",
                alt: [
                    {
                        name: "Zero Emission Deliveries",
                    },
                ],
            },
            {
                name: "People + Planet Positive Sales",
                path: "/sustainability/sustainable-sales",
                color: "#8eec8e",
                alt: [
                    {
                        name: "People Planet Positive Sales %",
                    },
                ],
            },
        ],
    },
    {
        name: "Better Lives",
        path: "/better-lives",
        areas: [
            {
                name: "Engagement Index",
                path: "/people/engagement-index",
                color: "#8eec8e",
            },
            {
                name: "Values Index",
                path: "/people/ikea-values",
                color: "#8eec8e",
                alt: [
                    {
                        name: "IKEA Values",
                    },
                ],
            },
            {
                name: "Leadership Index",
                path: "/people/leadership-index",
                color: "#8eec8e",
                alt: [
                    {
                        name: "Leadership Index",
                    },
                ],
            },
            {
                name: "eNPS",
                path: "/people/e-nps",
                document: "eNPS",
                color: "#fbfbac",
                alt: [
                    {
                        name: "Happy Coworker",
                    },
                    {
                        name: "Happy Co-worker",
                    },
                ],
            },
            {
                name: "Social Impact",
                path: "/people/social-impact",
                document: "SocialImpact",
                color: "#8eec8e",
            },
            {
                name: "Change Makers",
                path: "/people/change-makers",
                document: "ChangeMakers",
                color: "#8eec8e",
            },
            {
                name: "Retention",
                path: "/people/balanced-co-worker-turnover",
                color: "#8eec8e",
                document: "Co-WorkerTurnover",
                alt: [
                    {
                        name: "Co-Worker Turnover",
                    },
                ],
            },
        ],
    },
    {
        name: "Better Company",
        path: "/better-company",
        areas: [
            {
                name: "Profit & Loss",
                path: "/finance/profit-and-loss",
                color: "#8eec8e",
                alt: [
                    {
                        name: "Total Revenue",
                    },
                    {
                        name: "Cost Ratio",
                    },
                    {
                        name: "Operational Result",
                    },
                ],
            },
            {
                name: "Sales",
                color: "#8eec8e",
                areas: [
                    {
                        name: "Sales Tree",
                        path: "/business/sales-tree",
                        color: "#8eec8e",
                        alt: [
                            {
                                name: "Total Offline",
                            },
                            {
                                name: "Visitation Offline",
                            },
                            {
                                name: "Visitation Online",
                            },
                            {
                                name: "Online Conversion",
                            },
                            {
                                name: "Visitation, Comp. Offline",
                            },
                            {
                                name: "Visitation, Comp Offline",
                            },
                        ],
                    },
                    {
                        name: "Sales Trend Total",
                        path: "/business/total-sales/sales-trend-total",
                        location: "/business/total-sales/sales-trend-total",
                        color: "#8eec8e",
                        alt: [
                            {
                                name: "Total Sales Dev",
                                filter: "Total",
                            },
                            {
                                name: "Comp Cash & Carry",
                                filter: "Store comp",
                            },
                        ],
                    },
                    {
                        name: "Sales contribution per country",
                        path: "/business/total-sales/sales-contribution-per-country",
                        color: "#8eec8e",
                    },
                    {
                        name: "Channel contribution per country",
                        path: "/business/total-sales/channel-contribution-per-country",
                        color: "#8eec8e",
                    },
                    {
                        name: "Sales and quantity per country",
                        path: "/business/total-sales/sales-and-quantity-per-country",
                        document: "SalesandQuantity",
                        color: "#8eec8e",
                    },
                    {
                        name: "HFB",
                        color: "#8eec8e",
                        path: "/business/sales-per-range/hfb",
                    },
                    {
                        name: "HF Accessories",
                        path: "/business/sales-per-range/hf-accessories",
                        color: "#8eec8e",
                        alt: [
                            {
                                name: "HFA",
                            },
                        ],
                    },
                    {
                        name: "Top Families Growth",
                        color: "#8eec8e",
                        path: "/business/sales-per-range/top-families-growth",
                    },
                    {
                        name: "Food Sales",
                        path: "/business/sales-per-range/food-sales",
                        color: "#8eec8e",
                    },
                    {
                        name: "IKEA Business share of sales",
                        path: "/business/ikea-business-share-of-sales-b2b",
                        color: "#8eec8e",
                    },
                    {
                        name: "Sales Prioritised cities",
                        path: "/business/total-sales/sales-prioritized-thirty-cities",
                        color: "#fbfbac",
                    },
                    {
                        name: "Sales per country vs Goal",
                        path: "/business/total-sales/sales-per-country-vs-goal",
                        color: "#8eec8e",
                    },
                    {
                        name: "IKEA Family share of sales",
                        path: "/business/ikea-family-share-of-sales",
                        document: "IKEAFamilyShareOfSales",
                        color: "#8eec8e",
                    },
                ],
            },
            {
                name: "Cost",
                color: "#8eec8e",
                areas: [
                    {
                        name: "Cost per Country vs Goal",
                        path: "/finance/cost-per-country-vs-goal",
                        location: "/finance/cost-per-country-vs-goal",
                        color: "#8eec8e",
                    },
                ],
            },
            {
                name: "Margin",
                areas: [
                    {
                        name: "Gross Margin per Country",
                        path: "/finance/gross-margin-per-country",
                        location: "/finance/operational-gross-margin",
                        color: "#8eec8e",
                        alt: [
                            {
                                name: "Gross Margin",
                                filter: "Total",
                            },
                        ],
                    },
                    {
                        name: "Operational Gross Margin",
                        path: "/finance/operational-gross-margin",
                        color: "#fbfbac",
                    },
                    {
                        name: "Operational Gross Margin Forecast vs Goal",
                        path: "/finance/operational-gross-margin-forecast-vs-goal",
                        color: "#fbfbac",
                    },
                ],
            },
            {
                name: "Result",
                areas: [
                    {
                        name: "Result Contribution per Country",
                        path: "/finance/result-per-country-vs-goal",
                        color: "#8eec8e",
                    },
                ],
            },
            {
                name: "Market Share",
                path: "/business/market-share",
                color: "#8eec8e",
            },
            {
                name: "Service Net",
                areas: [
                    {
                        name: "Services Gross Profit",
                        path: "/finance/service-net/services-gross-margin",
                        location: "/finance/service-net/services-gross-margin",
                        color: "#fbfbac",
                    },
                    {
                        name: "Services to sales ratio",
                        path: "/finance/services-sales-ratio",
                        color: "#8eec8e",
                    },
                ],
            },
            {
                name: "Financial Services",
                path: "/business/financial-services",
                color: "#8eec8e",
            },
            {
                name: "Source to pay indirect (S2PI)",
                path: "/process/procurement",
                color: "#8eec8e",
                alt: [
                    {
                        name: "Purchase Order Compliance",
                    },
                ],
            },
            {
                name: "Add to Cart Rate",
                path: "/business/add-to-cart-rate",
                document: "Add_to_cart_rate",
                color: "#8eec8e",
            },
            {
                name: "Perceived Customer Buyability",
                path: "/customer/online-buyability",
                color: "#8eec8e",
            },
            {
                name: "CFF",
                color: "#8eec8e",
                areas: [
                    {
                        name: "Product Availability",
                        path: "/business/product-availability",
                        color: "#8eec8e",
                        alt: [
                            {
                                name: "Product Availability OSA",
                            },
                            {
                                name: "Product Availability CDC",
                            },
                        ],
                    },
                    {
                        name: "Offered Lead Time",
                        path: "/business/offered-lead-time",
                        document: "OfferedLeadTime",
                        color: "#8eec8e",
                    },
                    {
                        name: "Cost Per Flow",
                        path: "/finance/cost-per-flow",
                        color: "#8eec8e",
                    },
                    {
                        name: "Customer Promised Service Precision",
                        path: "/customer/promised-delivery-precision",
                        color: "#8eec8e",
                    },
                ],
            },
        ],
    },
];
