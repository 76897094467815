<template>
  <div class="dropdown is-hoverable navbar-item">
    <div class="dropdown-trigger hidden-on-mobile">
        Extra
    </div>
    <div class="dropdown-menu" role="menu">
        <div class="dropdown-content">
            <a class="dropdown-item" @click="route_to(`/country-overview/country`)">
                <div>
                    Country Overview
                </div>
            </a>
            <a class="dropdown-item" v-if="$store.getters.check_access(['country-insights', '*', 'r'])" @click="route_to(`/country-insights/introduction/${$store.state.selected.market}`)">
                <div>
                    Insights
                </div>
            </a>
            <a class="dropdown-item" v-if="false && $store.getters.check_access(['executive-summary', '*', 'r'])" @click="route_to(`/executive-summary`)">
                <div>
                    Executive Summary
                </div>
            </a>

            <a class="dropdown-item" v-if="false && $store.state.global.is_ift_user" href="https://ingka.flight-tower.com/">
                Ingka Flight Tower
            </a>
        </div>
    </div>
</div>
</template>

<script>
export default {
    methods : {
        route_to: function(location) {
            this.$router.push(location);
        },
    }
}
</script>

<style scoped>
@media screen and (max-width: 1024px) {
    .dropdown-menu {
        display: block !important;
        position: relative !important;
    }
    .dropdown-content {
        border: none;
        box-shadow: none;
    }
}
.dropdown-content {
    padding: 0 !important;
    font-size: 1rem;
    background-color: #ffffff;
    border-radius: 0;
}

.dropdown-item {
    padding: 0.5rem 1rem 0rem 1rem !important;
    height: 2.5rem;
    font-size: 1rem;
}

.dropdown-item:hover {
    background-color: #d3d3d31f !important;
    color: #3273dc !important;
}

.dropdown-menu {
    min-width: 0 !important;
    padding: 0 !important;
}

.navbar-item:hover {
    background-color: #fafafa;
    color: #3273dc;
}
</style>