var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "columns is-gapless" }, [
    _vm._m(0),
    _c("div", { staticClass: "column is-8" }, [
      _c("div", [
        _vm._v("There should "),
        _c("b", [_vm._v("not")]),
        _vm._v(
          " be more than " +
            _vm._s(_vm.commentLimit) +
            " comment live at one time."
        ),
      ]),
      _c("div", [_vm._v("Please archive one comment.")]),
    ]),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-2" }, [
      _c("i", { staticClass: "fas fa-exclamation-circle" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-2" }, [
      _c("i", { staticClass: "fas fa-exclamation-circle" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }