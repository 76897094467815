<template>
<div>
    <Button class="full-width mb" @click="back()">Cancel Changes</Button>

    <BackModal
        :back_clicked="back_clicked"
        @clear-storage="clear_storage"
        @toggle-edit-mode="$emit('toggle-edit-mode')"
        @cancel="back_clicked = false"
    />

    <DeleteModal
        :delete_clicked="delete_clicked"
        @update="update"
        @cancel="delete_clicked = false"
    />

    <div class="modal" :class="`${delete_clicked ? 'is-active' : ''}`">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Delete Comment</p>
                <button class="delete" aria-label="close" @click="delete_clicked = false"></button>
            </header>
            <section class="modal-card-body">
                Deleting this comment will remove it completely from flight tower and it will not be visible or editable by anyone.
            </section>
            <footer class="modal-card-foot">
                <button class="button is-success" @click="update('DELETED')">Delete</button>
                <button class="button" @click="delete_clicked = false">Cancel</button>
            </footer>
        </div>
    </div>

    <div class="schedule mb">
        <SwitchBoxUniversal
            class="mb"
            label="Posting on someones behalf?"
            element_id="edit-switch"
            :boolean_item="this.commentToEdit.author_id ? true : false"
            @switch_toggle="switch_toggle"
        /> 
        <UserSearch
            v-if="posting_on_behalf"
            :display_name="this.commentToEdit.display_name"
            :user_id="this.commentToEdit.id"
            @SelectedUser="selected_user"
        />
        <WYSIWYGEditor
            @textUpdate="update_content"
            :content="updated_comment.content"
        />
        <div class="character-count" :class="character_alert ? 'character-alert' : ''">{{character_count}}/2250
            <div v-if="character_alert">Text may have to be cut off when put into the Powerpoint extract</div>
        </div>
        <p>Select the start and end dates for the comment:</p>
        <DateTimePicker
            :default="[commentToEdit.scheduled_date, commentToEdit.comment_lifetime]"
            @updated="update_scheduled_date"
        />
    </div>
    
    <InfoButtons 
        :buttons="buttons"
        :calculated_status="calculated_status"
        @update="update"
        @delete_button="delete_button"
    />

    <div style="margin-bottom: 6rem;"></div>
</div>
</template>

<script>
import BackModal from '@/components/bespoke/commenting/BackModal.vue';
import DeleteModal from '@/components/bespoke/commenting/DeleteModal.vue';
import Button from '@/components/ui/Button.vue';
import DateTimePicker from '@/components/bespoke/commenting/DateTimePicker.vue';
import DropDown from '@/components/ui/DropDown.vue';
import InfoButtons from '@/components/ui/InfoButtons.vue';
import SwitchBoxUniversal from '@/components/ui/SwitchBoxUniversal.vue';
import UserSearch from '@/components/admin/UserSearch.vue';
import WYSIWYGEditor from '@/components/bespoke/commenting/QuillWYSIWYGEditor.vue';

export default {
    components: {
        BackModal,
        Button,
        DateTimePicker,
        DeleteModal,
        DropDown,
        InfoButtons,
        UserSearch,
        SwitchBoxUniversal,
        WYSIWYGEditor
    },
    props: {
        commentToEdit: Object,
        status: String,
        archive_comment: Boolean
    },
    data: function() {
        return {
            updated_comment: {
                content: localStorage[this.commentToEdit.comment_id] ? localStorage[this.commentToEdit.comment_id] : this.commentToEdit.content,
                scheduled_date: null
            },
            posting_on_behalf: null,
            character_count: 0,
            character_alert: false,
            back_clicked: false,
            delete_clicked: false,
            delete_info: false,
            buttons: [
                {
                    type: 'post',
                    clicked: false,
                    button: 'Comment',
                    info: `This button saves the comment to the`,
                    operation: null
                },
                {
                    type: 'draft',
                    clicked: false,
                    button: 'Save to Draft',
                    info: 'This button saves the comment to the Draft tab',
                    operation: 'DRAFT'
                },
                {
                    type: 'archive',
                    clicked: false,
                    button: 'Archive Comment',
                    info: 'This button will sent the comment to the archived tab',
                    operation: 'ARCHIVED'
                },
                {
                    type: 'delete',
                    clicked: false,
                    button: 'Delete',
                    info: `This button will delete the comment and it will be removed from Flight Tower. If a mistake is made the comment can be retrived if you contact the site's maintenaince team`,
                    operation: null
                }
            ]
        }
    },
    mounted: function() {
        this.updated_comment.id = this.commentToEdit.comment_id;
        this.updated_comment.scheduled_date = this.commentToEdit.scheduled_date.split('T')[0];
        this.updated_comment.comment_lifetime = this.commentToEdit.comment_lifetime.split('T')[0];
        this.posting_on_behalf = this.commentToEdit.author_id ? true : false;
        this.character_counter(this.commentToEdit.content);
    },
    methods: {
        back: function() {
            if (localStorage[this.commentToEdit.comment_id]) {
                this.back_clicked = true;
            } else {
                this.$emit('toggle-edit-mode');
            }
        },
        clear_storage: function() {
            delete localStorage[this.commentToEdit.comment_id];
        },
        delete_button: function() {
            this.delete_clicked = true;
        },
        update: function(status) {
            let update = {};
            for (let key in this.updated_comment) {
                update[key] = this.updated_comment[key];
            }
            if (!update.scheduled_date || !update.comment_lifetime) {
                alert('Please select and start and end date');
            } else {
                if (update.scheduled_date) { //check if first date selected is todays date
                    let today = new Date().getTime();
                    let input_date = update.scheduled_date.split('-');
                    let input_timestemp = new Date(input_date[1]+"/"+input_date[2]+"/"+input_date[0]).getTime();
                    if (status) {
                        update.status = status;
                    } else {
                        if (input_timestemp < today) {
                            update.status !== 'LIVE' ? update.status = 'LIVE' : null; //if post date is today make it live
                        } else {
                            update.status !== 'SCHEDULED' ? update.status = 'SCHEDULED' : null; //if post date in the future make it scheduled
                        }
                    }
                }
                if (Object.keys(update).length > 0) {
                    this.$chttp.post(`/comments/edit`, update).then(
                        succ => {
                            this.$emit('updated', update.status);
                            this.clear_storage();
                        },
                        error => {
                            console.log(error);
                        }
                    );
                }
            }
        },
        update_content: function(text) {
            this.updated_comment.content = text;
            this.character_counter(text);
            localStorage[this.commentToEdit.comment_id] = text;
        },
        character_counter: function(text) {
            let result = text.replace(/(<([^>]+)>)/ig, "").length; 
            this.character_count = result;
            this.character_alert = result > 2250 ?  true : false;
        },
        update_scheduled_date: function(date) {
            this.updated_comment.scheduled_date = date[0];
            this.updated_comment.comment_lifetime = date[1];
        },
        selected_user: function(user) {
            this.updated_comment.author_id = user.id;
        },
        switch_toggle: function() {
            this.posting_on_behalf = !this.posting_on_behalf;
            if (!this.posting_on_behalf) this.updated_comment.author_id = null;
        }
    },
    computed: {
        calculated_status: function() {
            if (this.updated_comment.scheduled_date) {
                let today = new Date().getTime();
                let input_date = this.updated_comment.scheduled_date.split('-');
                let input_timestemp = new Date(input_date[1]+"/"+input_date[2]+"/"+input_date[0]).getTime();
                if (input_timestemp < today) {
                    return 'LIVE' //if post date is today make it live
                } else {
                    return 'SCHEDULED' //if post date in the future make it scheduled
                }
            }
        }
    }
}
</script>

<style scoped>
.is-centered {
    justify-content: space-between !important;
}
.buttons.is-centered:not(.has-addons) .button:not(.is-fullwidth) {
    margin: 0;
}
.character-alert {
    background-color: #ff0000;
    color:#f5f5f5;
}
.section {
    background-color: rgb(245, 245, 245);
}
.schedule {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5em;
    background-color: #fbfbfb;
}

</style>