var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Button",
        {
          staticClass: "full-width mb",
          on: {
            click: function ($event) {
              return _vm.back()
            },
          },
        },
        [_vm._v("Cancel Changes")]
      ),
      _c("BackModal", {
        attrs: { back_clicked: _vm.back_clicked },
        on: {
          "clear-storage": _vm.clear_storage,
          "toggle-edit-mode": function ($event) {
            return _vm.$emit("toggle-edit-mode")
          },
          cancel: function ($event) {
            _vm.back_clicked = false
          },
        },
      }),
      _c("DeleteModal", {
        attrs: { delete_clicked: _vm.delete_clicked },
        on: {
          update: _vm.update,
          cancel: function ($event) {
            _vm.delete_clicked = false
          },
        },
      }),
      _c(
        "div",
        {
          staticClass: "modal",
          class: `${_vm.delete_clicked ? "is-active" : ""}`,
        },
        [
          _c("div", { staticClass: "modal-background" }),
          _c("div", { staticClass: "modal-card" }, [
            _c("header", { staticClass: "modal-card-head" }, [
              _c("p", { staticClass: "modal-card-title" }, [
                _vm._v("Delete Comment"),
              ]),
              _c("button", {
                staticClass: "delete",
                attrs: { "aria-label": "close" },
                on: {
                  click: function ($event) {
                    _vm.delete_clicked = false
                  },
                },
              }),
            ]),
            _c("section", { staticClass: "modal-card-body" }, [
              _vm._v(
                " Deleting this comment will remove it completely from flight tower and it will not be visible or editable by anyone. "
              ),
            ]),
            _c("footer", { staticClass: "modal-card-foot" }, [
              _c(
                "button",
                {
                  staticClass: "button is-success",
                  on: {
                    click: function ($event) {
                      return _vm.update("DELETED")
                    },
                  },
                },
                [_vm._v("Delete")]
              ),
              _c(
                "button",
                {
                  staticClass: "button",
                  on: {
                    click: function ($event) {
                      _vm.delete_clicked = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "schedule mb" },
        [
          _c("SwitchBoxUniversal", {
            staticClass: "mb",
            attrs: {
              label: "Posting on someones behalf?",
              element_id: "edit-switch",
              boolean_item: this.commentToEdit.author_id ? true : false,
            },
            on: { switch_toggle: _vm.switch_toggle },
          }),
          _vm.posting_on_behalf
            ? _c("UserSearch", {
                attrs: {
                  display_name: this.commentToEdit.display_name,
                  user_id: this.commentToEdit.id,
                },
                on: { SelectedUser: _vm.selected_user },
              })
            : _vm._e(),
          _c("WYSIWYGEditor", {
            attrs: { content: _vm.updated_comment.content },
            on: { textUpdate: _vm.update_content },
          }),
          _c(
            "div",
            {
              staticClass: "character-count",
              class: _vm.character_alert ? "character-alert" : "",
            },
            [
              _vm._v(_vm._s(_vm.character_count) + "/2250 "),
              _vm.character_alert
                ? _c("div", [
                    _vm._v(
                      "Text may have to be cut off when put into the Powerpoint extract"
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _c("p", [_vm._v("Select the start and end dates for the comment:")]),
          _c("DateTimePicker", {
            attrs: {
              default: [
                _vm.commentToEdit.scheduled_date,
                _vm.commentToEdit.comment_lifetime,
              ],
            },
            on: { updated: _vm.update_scheduled_date },
          }),
        ],
        1
      ),
      _c("InfoButtons", {
        attrs: {
          buttons: _vm.buttons,
          calculated_status: _vm.calculated_status,
        },
        on: { update: _vm.update, delete_button: _vm.delete_button },
      }),
      _c("div", { staticStyle: { "margin-bottom": "6rem" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }