<template>
<div class="dropdown is-hoverable is-right">
    <div class="dropdown-trigger">
        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
            <span class="icon">
                <i class="fas fa-question-circle" aria-hidden="true"></i>
            </span>
        </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
        <div class="dropdown-content">
            <a v-for="(link, i) in help_links" :key="i" class="dropdown-item" :href="link.link" :target="link.link.includes(url_origin) ? '_self' : '_blank'">
                {{link.name}}
            </a>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data: function() {
        return {
            help_links: [],
            url_origin: window.location.origin
        }
    },
    mounted: function() {
        this.$http.get('/help-links/get').then(
            succ => {
                if (succ.data.length > 0) {
                   this.help_links = succ.data;
                }
            },
            error => {
                console.log(error);
            }
        );
    }
}
</script>

<style scoped>
.nested.dropdown .dropdown-trigger button::after {
  content: "";
}
.navbar-dropdown {
    overflow-y: scroll;
    overflow-x: hidden;
}
.navbar-item {
    padding: 0.5rem 0.5rem !important;
    user-select: none;
}
.navbar-item:hover {
    background-color: rgba(0, 174, 255, 0.192);
}
.first {
    padding-top:0.1em;
}
.fa-question {
    font-size:1.2em;
    float: right;
    padding-right: 0.65em;
    padding-top: 0.2em;
}
 @media only screen and (max-width: 1024px) {
    .dropdown-content {
		box-shadow: 0 0 0 !important; 
	}
	a.dropdown-item {
		padding-left: -1rem !important;
		text-align: center !important; 
		width: 100vw ! important;
	}
	.dropdown-trigger {
    	visibility: hidden;
		width: 0;
		height: 0;
	}
}
button.button {
    width: auto;
    height: auto; 
    border-radius: 0;
    border: 0;
    font: inherit;
}
button.button:hover {
    background-color: #fafafa;
}
.dropdown-menu {
    padding: 0;
}
.dropdown-content {
    border-radius: 0;
    padding: 0;
    text-align: left;
    width: auto;
}
.dropdown-item {
    height: 2.5rem;
    font: inherit;
}
a.dropdown-item {
    text-align: center !important;
    padding: 0.5rem 0.5rem 0rem 0.5rem !important;
}
a.dropdown-item:hover {
    background-color: #d3d3d31f !important;
    color: #3273dc !important;
}
.dropdown-item:hover {
    background-color: rgba(181, 229, 245, 0.788);
}
.fa-question-circle {
    color: #0051BA;
    font-size: 2em;
}

</style>
