<template>
    <aside class="menu" v-if="render && menu" :key="render">
        <p class="menu-label">KPIs</p>
        <ul class="menu-list">
            <!-- As per fredrik, hiding non used links to declutter dashboard - TJ 7/10/29 -->
            <li>
                <router-link :to="menu['KPI Definitions']">KPI Definitions</router-link>
            </li>

            <li>
                <router-link :to="menu['KPI Definitions Feedback']">KPI Definitions Feedback</router-link>
            </li>

            <li>
                <router-link :to="menu['Enable/Disable KPIs']">Enable/Disable KPIs</router-link>
            </li>

            <li>
                <router-link :to="menu['Load Tracker']">Load Tracker</router-link>
            </li>

            <!-- <li>
            <router-link to="menu['Which KPIs have comments?']">Which KPIs have comments?</router-link>
        </li> -->

            <li>
                <router-link :to="menu['KPI Owners']">KPI Accountables</router-link>
            </li>

            <li>
                <router-link :to="menu['KPI Contacts']">KPI Contacts</router-link>
            </li>

            <li>
                <router-link :to="menu['Retail Managers']">Retail Managers</router-link>
            </li>

            <!-- <li>
            <router-link :to="menu['KPI Pictures']">KPI Pictures</router-link>
        </li> -->
        </ul>

        <p class="menu-label">Announcements</p>
        <ul class="menu-list">
            <li>
                <router-link :to="menu['Global Announcements']">Announcements</router-link>
            </li>
        </ul>

        <p class="menu-label">Net Promotor Score</p>
        <ul class="menu-list">
            <li>
                <router-link :to="menu['All Forms']">Forms</router-link>
                <ul>
                    <li><router-link :to="menu['All Forms']">All</router-link></li>
                    <li><router-link :to="menu['New Form']">New</router-link></li>
                </ul>
            </li>
            <li>
                <router-link :to="menu['All Questions']">Questions</router-link>
                <ul>
                    <li><router-link :to="menu['All Questions']">All</router-link></li>
                    <li><router-link :to="menu['New Question']">New</router-link></li>
                </ul>
            </li>
        </ul>

        <p class="menu-label">Other</p>
        <ul class="menu-list">
            <li>
                <router-link :to="menu['Access Templates']">User Management</router-link>
                <ul>
                    <li><router-link :to="menu['Access Templates']">Access Templates</router-link></li>
                    <li><router-link :to="menu['Approve Access Requests']">Approve Access Requests</router-link></li>
                    <li><router-link :to="menu['Edit Users']">Edit Users</router-link></li>
                </ul>
            </li>

            <li>
                <router-link :to="menu['Observations Tracker']">Observations Tracker</router-link>
            </li>

            <li>
                <router-link :to="menu['Insights Templates']">Insights Templates</router-link>
            </li>

            <li>
                <router-link :to="menu['Insights Responsibles']">Insights Responsibles</router-link>
            </li>

            <li>
                <router-link :to="menu['Insights Reminders']">Insights Reminders</router-link>
            </li>

            <!-- does not exist??
            <li>
                <router-link to="menu['Country Insights Tracker']">Insights Tracker</router-link>
            </li> 
            -->

            <li>
                <router-link :to="menu['Help Links']">Help Links</router-link>
            </li>

            <li>
                <router-link :to="menu['Known Issues']">Known Issues</router-link>
            </li>

            <!-- <li>
            <router-link :to="menu['Metrics']">
                Metrics
            </router-link>
        </li> -->

            <!-- OLD Link to be updated
        
            <li>
                <a
                    target="_blank"
                    href="https://analytics.google.com/analytics/web/#/report-home/a154855450w218473163p208310911  "
                >
                    Google Analytics (Metrics)
                </a>
            </li> -->

            <!-- <li>
            <router-link :to="menu['Release Notes']">
                Release Notes
            </router-link>
        </li> -->
        </ul>
    </aside>
</template>

<script>
export default {
    data: function () {
        return {
            menu: {},
            render: false
        };
    },
    mounted: function () {
        this.render = false;
        const find_all_menu_children = (items, base_route) => {
            if (!items) return;
            if (items.length === 0) return;

            let child = items.shift();
            if (!child) return;

            let child_route = `${base_route}/${child.path}`;
            if (child.children && child.children.length > 0) {
                find_all_menu_children([...child.children], child_route);
            }

            if (child.name) {
                this.menu[child.name] = child_route;
            }

            find_all_menu_children([...items], base_route);
        };

        import('@/routes/admin.js').then(
            data => {
                let menu_items = [...data.default[0].children];
                find_all_menu_children(menu_items, '/admin');
                this.render = true;
            },
            err => console.log(err)
        );
    }
};
</script>

<style>
aside.menu {
    padding-left: 2em;
    text-align: left;
}

.router-link-exact-active.router-link-active {
    background-color: #0051ba;
    color: #fff;
}

aside.menu a:hover {
    background-color: #0051ba;
    color: #fff;
}

aside.menu .router-link-exact-active.router-link-active + ul {
    display: block;
}

aside.menu .router-link-active + ul {
    display: block;
}

aside.menu li ul {
    display: none;
}
</style>
