var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.$store.state.global.legacy_menu
    ? _c(
        "div",
        {
          staticClass: "navbar-item navbar-link text-left",
          on: {
            mouseover: function ($event) {
              return _vm.open_dropdown()
            },
            mouseleave: function ($event) {
              return _vm.close_dropdown()
            },
          },
        },
        [
          _c("div", { staticClass: "mobile-title" }, [_vm._v("KPIs")]),
          _c(
            "div",
            {
              staticClass: "navbar-dropdown",
              attrs: { id: "navbar-dropdown" },
            },
            [
              _c(
                "div",
                { staticClass: "nested navbar-item dropdown dropdown-text" },
                [
                  _c("div", { staticClass: "dropdown-trigger mobile-bold" }, [
                    _c(
                      "div",
                      {
                        attrs: {
                          "aria-haspopup": "true",
                          "aria-controls": "dropdown-menu",
                        },
                      },
                      [
                        _c("div", { staticClass: "tile is-ancestor" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "tile is-vertical is-parent dropdown-column",
                            },
                            [_c("KpiDropdownItem", { attrs: { index: 0 } })],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "tile is-vertical is-parent dropdown-column",
                            },
                            [_c("KpiDropdownItem", { attrs: { index: 1 } })],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "tile is-vertical is-parent dropdown-column",
                            },
                            [_c("KpiDropdownItem", { attrs: { index: 2 } })],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "tile is-vertical is-parent dropdown-column",
                            },
                            [_c("KpiDropdownItem", { attrs: { index: 3 } })],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }