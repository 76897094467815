<template>
<div class="flex">
    <div class="dropdown is-hoverable navbar-item main-dropdown" v-if="!$store.state.global.legacy_menu">
        <a class="navbar-link dropdown-trigger text-bold align-right">
            {{$store.getters.selected_country_name}}
        </a>
        <!-- <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
                <div @click="update_filter(null)"
                    class="dropdown-item columns is-gapless"
                    :class="'Total Retail Countries' === $store.getters.selected_country_name ? 'selected_country':''"
                >
                    <div class="column is-8">Total Retail Countries</div>
                    <div class="column is-4">
                        <i class="dropdown-globe globe-outline">
                            <i class="fas fa-globe-europe"></i>
                        </i>
                    </div>
                </div>
                <div  class="dropdown-item columns is-gapless"
                    :class="country.caption === $store.getters.selected_country_name ? 'selected_country':''"
                    @click="update_filter(country)" 
                    v-for="(country, i) in displayed_countries" 
                    :key="i"
                >
                    <div class="column is-8">
                        <div class="ptp1">{{country.caption}}</div>
                    </div>
                    <div class="column is-4">
                        <div class="dropdown-flag" v-bind:class="render_flag(country)"></div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
                <div v-for="(country_column, i) in displayed_countries" class="columns is-gapless" :key="i">
                    <div  class="dropdown-item column"
                        :class="`
                            ${country && country.caption === $store.getters.selected_country_name ? 'selected_country ':''}
                            ${!country ? 'no-hover': ''}
                        `"
                        @click="country ? update_filter(country) : ''"
                        v-for="(country, j) in country_column"
                        :key="j"
                    >
                        <div class="columns is-gapless">
                            <div class="column is-3">
                                <div v-if="!country"></div>
                                <div v-else-if="country.code" class="dropdown-flag mobile-flag" v-bind:class="country ? render_flag(country) : ''"></div>
                                <i v-else class="dropdown-globe globe-outline">
                                    <i class="fas fa-globe-europe"></i>
                                </i>
                            </div>
                            <div class="column is-9">
                                <div class="pap5 mobile-text">{{country ? country.caption : ''}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
	<a class="navbar-link" v-else @click="select_country">
		Select Country
	</a>
    <div v-if="$store.getters.flag" class="image-placement hidden-on-mobile">
        <div v-bind:class="render_flag_market_only($store.getters.flag)" class="menu-flag"></div>
    </div>
    <div v-else class="image-placement hidden-on-mobile">
        <div class="globe-outline inline-block main-global"><i class="fas fa-globe-europe"></i></div>
    </div>
</div>
</template>

<script>
export default {
    mounted: function() {
        let saved = JSON.parse(localStorage.getItem('saved-countries')); // { caption, market }
        if (saved) {
            let { caption, market } = saved;
            if (!caption || !market) {
                this.$store.commit('update_country', {
                    market: null
                });
                return;
            }

            this.$store.state.selected.country = caption;
            this.$store.state.selected.market = market;
            this.$store.state.selected.flag = market;
        }
    },
    methods: {
        update_filter: function(country) {
            if (country) {
                this.$store.commit('update_country', {
                    market: country.code,
                    country: country.caption
                });
                localStorage.setItem('saved-countries', JSON.stringify({ caption: country.caption, market: country.code }));
            } else {
                this.$store.commit('update_country', {
                    market: null
                });
                localStorage.setItem('saved-countries', JSON.stringify({ caption: 'Total Retail Countries', market: null }));
            }
        },
        select_country: function() {
            this.$store.commit('set_routing', true);
            this.$router.push(`/country-select-only/${encodeURIComponent(this.$router.currentRoute.path)}`);
        },
        render_flag: function(country) {
            if (country && country.code) {
                let key = `flag-icon flag-icon-${country.code.toLowerCase()}`;
                let obj = {};
                obj[key] = true;
                return obj;
            } 
            return {};
        },
        render_flag_market_only: function(country) {
            if (country) {
                let key = `flag-icon flag-icon-${country.toLowerCase()}`;
                let obj = {};
                obj[key] = true;
                return obj;
            } 
            return {};
        }
    },
    computed: {
        displayed_countries: function() {
            let country_rows = [[{caption: 'Total Retail Countries', code: null}], [], [], [], []];
            if (this.$store.state.countries.all) {
                let row = 0;
                let all_countries = Object.entries(this.$store.state.countries.all);
                let countries_per_column = Math.ceil(all_countries.length / 5) - 1;
                all_countries.forEach(country => {
                    if (country_rows[row].length > countries_per_column) row ++;
                    country_rows[row].push({caption: country[1].caption, code: country[1].market});
                });
                while (country_rows[row].length <= countries_per_column) {
                    country_rows[row].push(null);
                }
            }
            return country_rows;
        }
    }
}
</script>

<style scoped>
@media only screen and (max-width: 1024px) {
    .dropdown {
        height: fit-content;
    }
    
    .dropdown-content {
        width: calc(100vw - 1em) !important;
	}

	.dropdown-flag {
        height: 1.5em;
        width: 2em;
        position: absolute;
        top: 2.5rem !important;
        right: 1rem;
    }

    .dropdown-globe {
        margin-top: -1.5rem !important;
    }

    .dropdown-trigger:hover {
        background-color: #ffffff !important;
    }

    .dropdown-trigger:after {
        border: none !important;
    }

    .navbar-item, .navbar-link {
        padding: 0.25rem 0.5rem 0.5rem 0.5rem !important;
    }

    .navbar-item:hover {
        background-color: #ffffff !important;
    }

    .dropdown-item {
        width: calc(100vw - 2rem) !important;
    }

    .dropdown-menu {
        display: block !important;
        position: relative !important;
        box-shadow: none !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .globe-outline {
        top: 1.75rem !important;
        right: 1rem;

    }
    .dropdown-content {
        padding: 0rem !important;
    }
    .mobile-flag {
        margin-top: -2rem !important;
    }
    .mobile-text {
        padding-left: 3rem;
    }
}

.dropdown {
    position: static !important;
}

.dropdown-globe {
    left: 0.5rem;
    top: 0.25rem;
}

.columns.is-gapless:not(:last-child) {
    margin-bottom: 0;
}

.columns:not(:last-child) {
    margin-bottom: 0; 
}

.dropdown-content {
	text-align: left;
    padding: 0.75rem;
}

.dropdown-flag {
    height: 1.5em;
    width: 2em;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
}

.menu-flag {
    height: 1.5em;
    width: 2em;
    position: absolute;
    top: 1rem;
}

.dropdown-item {
	font: inherit; 
    height: 2.5rem;
    line-height: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-item:hover {
    color: #3273dc;
    background-color: #e2e2e2;
}

.no-hover {
    color: inherit !important;
    background-color: transparent !important;
}

.dropdown-menu {
    width: 100vw;
    background-color: #fafafa;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding-top: 0 !important;
    padding-right: 0.5rem;
}

.fa-globe-europe {
	color: blue;
    font-size:1.95em;
}

.globe-outline {
	background-color: rgb(5, 214, 5);
	border-radius: 1em;
	position: absolute;
	height: 1.9em;
	width: 1.9em;
}

.image-placement{
	cursor: default;
    float: right;
	padding-top: 1rem;
    width: 3em;
    position: relative;
}

.main-global {
    margin-top: -0.25rem;
}

.navbar-item {
    user-select: none;
    position: static;
}

.navbar-item:hover {
    background-color: #fafafa;
}

.navbar-link:hover {
    color: #3273dc !important;
}

.ptp1 {
    padding-top: 0.1rem !important;
}

.selected_country {
    background-color: rgb(226, 226, 226);
}
</style>