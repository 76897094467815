<template>
<div class="navbar-item navbar-link text-left" v-if="!$store.state.global.legacy_menu"
    @mouseover="open_dropdown()"
    @mouseleave="close_dropdown()"
>
    <div class="mobile-title">KPIs</div>
    <div id="navbar-dropdown" class="navbar-dropdown">
        <div class="nested navbar-item dropdown dropdown-text">
            <div class="dropdown-trigger mobile-bold">
                <div aria-haspopup="true" aria-controls="dropdown-menu">
                    <div class="tile is-ancestor">
                        <div class="tile is-vertical is-parent dropdown-column">
                            <KpiDropdownItem :index="0"/>
                        </div>
                        <div class="tile is-vertical is-parent dropdown-column">
                            <KpiDropdownItem :index="1"/>
                        </div>
                        <div class="tile is-vertical is-parent dropdown-column">
                            <KpiDropdownItem :index="2"/>
                        </div>
                        <div class="tile is-vertical is-parent dropdown-column">
                            <KpiDropdownItem :index="3"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import KpiDropdownItem from '@/components/ui/KpiDropdownItem.vue';
export default {
    components: {
        KpiDropdownItem
    },
    methods: {
        open_dropdown: function() {
            document.getElementById("navbar-dropdown").style.display = 'block';
        },
        close_dropdown: function() {
            document.getElementById("navbar-dropdown").style.display = 'none';
        }
    }
}
</script>

<style scoped>
@media screen and (max-width: 1228px) {
    .navbar-dropdown {
        left: -11rem !important;
    }
}

@media screen and (max-width: 1024px) {
    .navbar-link:hover {
        background-color: #ffffff;
    }
    .mobile-title {
        color: #4a4a4a;
        text-align: center !important;
        font-weight: bold;
    }
}
@media screen and (min-width: 1024px) {
    .tile.is-vertical {
        width: 25vw;
    }
}

.navbar-dropdown {
    cursor: default;
    width: 100vw;
    left: -21rem;
    max-height: 90vh; 
    overflow-y: auto; 
    overflow-x: hidden; 
}

  
   

.tile.is-vertical > .tile.is-child:not(:last-child) {
    margin-bottom: 0rem !important;
}
</style>