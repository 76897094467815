<template>
<div class="modal" :class="{'is-active': delete_clicked}">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Delete Comment</p>
            <button class="delete" aria-label="close" @click="delete_clicked = false"></button>
        </header>
        <section class="modal-card-body">
            Deleting this comment will remove it completely from flight tower and it will not be visible or editable by anyone.
        </section>
        <footer class="modal-card-foot">
            <button class="button is-success" @click="delete_button">Delete</button>
            <button class="button" @click="$emit('cancel')">Cancel</button>
        </footer>
    </div>
</div>
</template>

<script>
export default {
    props: {
        delete_clicked: Boolean
    },
    methods: {
        delete_button: function() {
            this.$emit('update', 'DELETED')
        }
    }
}
</script>