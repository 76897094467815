import Country from '@/views/country-overview/Country.vue';
import KPI from '@/views/country-overview/KPI.vue';
import Benchmark from '@/views/country-overview/Benchmark.vue';

export default [
    {
        path: '/country-overview/country',
        component: Country,
        props: true
    },
    {
        path: '/country-overview/kpi/:market',
        component: KPI,
        props: true
    },
    {
        path: '/country-overview/benchmark',
        component: Benchmark,
        props: true
    }
];