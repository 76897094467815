<template>
    <div>
       
        <!-- <Title
            :title="title"
            :location="location"
        />
        <div class="options-bar">
            <Buttons
                v-if="buttons.length"
                :buttons="buttons"
                class="is-pulled-left"
                power_bi
                @setPbiPage="set_pbi_page"
            />
            <div class="label-container hidden-on-pc">
                <p>Country</p>
                <DropDown
                    :key="$store.getters.market"
                    :options="$store.state.countries.options"
                    :values="$store.state.countries.values"
                    :default="$store.getters.market"
                    @updateDropDown="update_view"
                />
            </div>
        </div> -->
    
        <div v-if="!errored" id="embedContainer" 
        :style="{ width: dimensions.width, height: dimensions.height}" 
        class="section" />
        <div v-else class="section box">
            <h2>There has been an error fetchig the power-bi report</h2>            
        </div>
        <div v-if="refreshpage_container" class="refresh-btn-contatiner">
            <p>The session has expired. Please click the button below to refresh the data.</p>
            <button @click="reloadPage"> Refresh Data</button>
        </div>
        <div v-if="reportRendered" id="report-rendered" ></div>
    </div>
</template>
    
<script>
import * as pbi from 'powerbi-client';
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
    },
    data() {
        return {
            errored: false,
            report: {},
            report_pages: [],            
            reportPageSize:{width:null,height:null},
            observationWidth:null,
            pbi_token_expiration:null,
            refreshpage_container: false,
            reportRendered: false,
        }
    },
    props: {
        buttons: {
            type: Array,
            required: false,
             default() {
            return []
        }
        },
        dimensions:{
            type: Object,
            default: { width: '1280px', height: '572px'}
        },  
        layout: {
            type: Object
        },
        location: {
            type: String,
            required: false,
            default: ''
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        group_id: {
            type: String,
            required: false,
            default: ''
        },
        report_id: {
            type: String,
            required: false,
            default: ''
        },
        set_page: {
            type: String,
            default: ''
        },
        pbi_filter: {
            type: Array,
            default: []
        },
        report_section: {
            type: String,
            default: ''
        }
    },
    computed:{
    },
    async mounted() {
        if (this.$route.params.market && this.$store.getters.market !== this.$route.params.market) {
            if (!this.$store.getters.countries.values) {
                let countries = await this.$http.get('/countries').catch(console.log);
                let data = countries.data;
                let values = ['null'];
                let options = [];
                data = data.sort((a, b) => a.caption - b.caption);
                data.map(item => {
                    values.push(item.market);
                    options.push(item.caption);
                });
                this.$store.commit('set_countries', {
                    all: countries.data.sort((a, b) => a.caption > b.caption ? 1 : -1),
                    options: ['Total Retail Countries', ...options],
                    values: values
                });
            }
            await this.$store.commit('update_market', this.$route.params.market);
        }
        await this.get_embed_token();      

    },  
    methods: {             
        activate_country_filter: function(pages, replace, report)  {
            if ((this.pbi_filter!=null) && (this.pbi_filter.length>0)){
                report.getFilters().then(filters => {
                    if (!filters.length) return;
                    report.updateFilters(replace,this.other_data_filter(filters));
                });
            }
            for (const page of pages) {
                page.getFilters().then(filters => {
                    if (!filters.length) return;
                    return page.updateFilters(replace, this.other_data_filter(filters));
                });
            }
        },
        market_data_filter: function(market_filter) {
            //Market filter value update for "All" market
            if (market_filter.column_name=="retail_unit_code" && (market_filter.table_name == "dim_market" || market_filter.table_name == "background"))
                return (this.$store.getters.market!=null)? this.$store.getters.market : "All";
            else
                return market_filter.values;
        },
        other_data_filter: function(filters) {
            for (const filter of filters) {
                let fdata = this.pbi_filter.find(f=>f.table_name==filter.target.table && f.column_name==filter.target.column);
                if (fdata!=undefined && fdata!=null)
                {
                    fdata.values = this.market_data_filter(fdata);
                    filter.values = [fdata.values];
                    filter.operator = fdata.conditional_operator;
                }
            }
            return filters;
        },
        embed_report: async function(report_info) {
            // Set up the configuration object that determines what to embed and how to embed it.
            // You can find out the available options by console logging pbi.models
            // Further documentation can be found here https://learn.microsoft.com/en-us/javascript/api/overview/powerbi/custom-layout
            
            this.pbi_token_expiration = report_info.tokenExpiration;
            
            let embedConfiguration =  {
                accessToken: report_info.embed_token,
                embedUrl: report_info.embedUrl,
                id: report_info.id,
                permissions: pbi.models.Permissions.All,
                tokenType: pbi.models.TokenType.Embed,
                type: 'report',
                settings: this.layout ? this.layout : {
                    layoutType: pbi.models.LayoutType.Custom,
                    customLayout: {
                        displayOption: pbi.models.DisplayOption.FitToPage,
                    },
                    // displayOption: pbi.models.DisplayOption.FitToPage,
                    filterPaneEnabled: false,  // Set to false to hide the filter pane
                    navContentPaneEnabled: false   // set false to disable report bottom tabs (switch pages button) 
                },
            };

            // Get a reference to the HTML element that contains the embedded report.
            let embedContainer = document.getElementById("embedContainer");
            // Embed the report.
            let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
            this.report = powerbi.embed(embedContainer, embedConfiguration);
            this.replace_all = pbi.models.FiltersOperations.ReplaceAll;
            
            this.report.on('rendered', () => {
                this.reportRendered = true;
            });

            this.report.on("loaded", () => {
                this.report.getPages().then(pages => {                

                    // match reportSection page and updating filter
                    if(this.report_section.length){
                        let is_reportSection = pages.find(page => page.name === this.report_section);
                        if(is_reportSection) this.set_report_section(this.report_section);
                    }

                    this.report_pages = pages;
                    this.set_pbi_page(this.set_page);
                    this.activate_country_filter(pages, this.replace_all, this.report);
         });
            }) 
             this.token_expire_event();
        },
        token_expire_event:function(){
            if(this.pbi_token_expiration){
                const currentTime = Date.now();
                const expiration = Date.parse(this.pbi_token_expiration);                               
                const timeUntilExpiration = expiration - currentTime;  // Time until token expiration in milliseconds                
                setTimeout(() => {
                                this.refreshpage_container = true;
                            }, timeUntilExpiration);
            }
        },
        reloadPage(){
            window.location.reload();
        },
        get_embed_token: function() {
            this.$http.post('pbi/embed-token', {
                group_id: this.group_id,
                report_id: this.report_id,
            }).then(succ => {
                if (!succ.data) return this.errored = true;
                this.embed_report(succ.data);
            }).catch(error => {
                this.errored = true;
            });
        },
        set_report_section: function(reportSection) {                     
            if (reportSection) this.report.setPage(this.report_section);
        },
        set_pbi_page: function(page) {
            if (!page.length) return;
            let found_page = this.report_pages.find(_page => _page.displayName.trim() === page);
            if (found_page) this.report.setPage(found_page.name);
        },
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.activate_country_filter(this.report_pages, this.replace_all, this.report);
        },
        '$store.state.commenting.width': function(newWidth){
            this.observationWidth = this.$store.state.commenting.width;            
        },
       
    }
};
</script>
    
<style scoped>
.section {
    overflow: hidden;
    margin: 0;
    padding: 0 1rem 0 4px;
    width:100%;   
}
.refresh-btn-contatiner button {
    background: #0051BA;
    color: white;
    width: 7vw;
    height: 33px;
    border: 0;
    border-radius: 5px;
    margin-top: 12px;
    cursor: pointer;
}

</style>