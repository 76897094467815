var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.render && _vm.menu
    ? _c("aside", { key: _vm.render, staticClass: "menu" }, [
        _c("p", { staticClass: "menu-label" }, [_vm._v("KPIs")]),
        _c("ul", { staticClass: "menu-list" }, [
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: _vm.menu["KPI Definitions"] } },
                [_vm._v("KPI Definitions")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: _vm.menu["KPI Definitions Feedback"] } },
                [_vm._v("KPI Definitions Feedback")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: _vm.menu["Enable/Disable KPIs"] } },
                [_vm._v("Enable/Disable KPIs")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: _vm.menu["Load Tracker"] } }, [
                _vm._v("Load Tracker"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: _vm.menu["KPI Owners"] } }, [
                _vm._v("KPI Accountables"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: _vm.menu["KPI Contacts"] } }, [
                _vm._v("KPI Contacts"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: _vm.menu["Retail Managers"] } },
                [_vm._v("Retail Managers")]
              ),
            ],
            1
          ),
        ]),
        _c("p", { staticClass: "menu-label" }, [_vm._v("Announcements")]),
        _c("ul", { staticClass: "menu-list" }, [
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: _vm.menu["Global Announcements"] } },
                [_vm._v("Announcements")]
              ),
            ],
            1
          ),
        ]),
        _c("p", { staticClass: "menu-label" }, [_vm._v("Net Promotor Score")]),
        _c("ul", { staticClass: "menu-list" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: _vm.menu["All Forms"] } }, [
                _vm._v("Forms"),
              ]),
              _c("ul", [
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: _vm.menu["All Forms"] } },
                      [_vm._v("All")]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: _vm.menu["New Form"] } }, [
                      _vm._v("New"),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: _vm.menu["All Questions"] } }, [
                _vm._v("Questions"),
              ]),
              _c("ul", [
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: _vm.menu["All Questions"] } },
                      [_vm._v("All")]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: _vm.menu["New Question"] } },
                      [_vm._v("New")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]),
        _c("p", { staticClass: "menu-label" }, [_vm._v("Other")]),
        _c("ul", { staticClass: "menu-list" }, [
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: _vm.menu["Access Templates"] } },
                [_vm._v("User Management")]
              ),
              _c("ul", [
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: _vm.menu["Access Templates"] } },
                      [_vm._v("Access Templates")]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: _vm.menu["Approve Access Requests"] } },
                      [_vm._v("Approve Access Requests")]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: _vm.menu["Edit Users"] } },
                      [_vm._v("Edit Users")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: _vm.menu["Observations Tracker"] } },
                [_vm._v("Observations Tracker")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: _vm.menu["Insights Templates"] } },
                [_vm._v("Insights Templates")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: _vm.menu["Insights Responsibles"] } },
                [_vm._v("Insights Responsibles")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: _vm.menu["Insights Reminders"] } },
                [_vm._v("Insights Reminders")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: _vm.menu["Help Links"] } }, [
                _vm._v("Help Links"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: _vm.menu["Known Issues"] } }, [
                _vm._v("Known Issues"),
              ]),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }