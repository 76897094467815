import Vue from 'vue';
import Vuex from 'vuex';
import router from './router.js';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        colors: {
            yellow: '#fddb2a',
            blue: '#0051ba',
            'focus-yellow': '#E5B700',
            'dark-grey': '#727272',
            grey: '#9C9C9C',
            'light-grey': '#BDBDBD',
            YG: '#000000',
            NGCT: [
                '',
                '#311B92',
                '#388E3C',
                '#F57F17',
                '#795548',
                '#F06292',
                '#9C27B0',
                '#00838F'
            ]
        },
        access: {
            access_template: null, // stores user's template on login
            access_template_name: null, // name of the template e.g 'ADMIN', 'BASIC'
            access_super_user: 0,
            show_access_request_modal: false
        },
        commenting: {
            width: localStorage['comment_width'] ? localStorage['comment_width'] : 0,
            loading: 0,
            show: true,
            notification: {
                show: false,
                message: null
            },
        },
        countries: {
            all: null,
            options: null,
            values: null
        },
        hfbs: {
            all: [],
            options: [],
            values: [],
        },
        stores: {
            all: [],
            parent: [],
            market: [],
            caption: [],
        },
        selected: {
            country: 'Total Retail Countries',
            market: null,
            flag: null,
        },
        global: {
            bot_mode: false,
            can_route: true,

            is_itf_user: false,
            if_pink_mode: false,
            legacy_menu: null,
            presentation_mode: false,
            show_edit: false,
            table: {
                font_size: 16
            },
            temp_filter: null,
            last_updated: "N/A",
            top_8_countries: ['GB', 'DE', 'FR', 'US', 'SE', 'CA', 'ES', 'IT', 'CN', 'NL'],
            route: window.location.pathname,
            window_height: window.innerHeight,
            window_width: window.innerWidth
        },
        loading: 0
    },
    mutations: {
        edit_commenting_width: (state, n) => {
            if (state.commenting.width === 6 && n === 1) return;
            if (state.commenting.width === 3 && n === -1) return;
            state.commenting.width = parseInt(state.commenting.width) + n;
            localStorage['comment_width'] = state.commenting.width;
        },
        set_access_template_name: (state, access_template_name) => {
            state.access.access_template_name = access_template_name;
        },
        set_super_user_permissions: (state, access_super_user) => {
            state.access.access_super_user = access_super_user;
        },
        set_access_request_show: (state, bool) => {
            state.access.show_access_request_modal = bool;
        },
        set_countries: (state, data) => {
            state.countries = data;
        },
        set_hfbs: (state, data) => {
            state.hfbs = data;
        },
        set_stores: (state, data) => {
            state.stores = data;
        },
        set_location: (state, location) => {
            state.global.route = location;
        },
        set_font: (state, difference) => {
            state.global.table.font_size += difference;
        },
        set_presentation_mode: (state, mode) => {
            state.global.presentation_mode = mode;
        },
        set_routing: (state, status) => {
            state.global.can_route = status;
        },
        set_filter: (state, filter) => {
            state.global.temp_filter = filter;
        },
        update_country: (state, meta) => {
            if (!meta.market) {
                state.selected.country = 'Total Retail Countries';
                state.selected.market = null;
                state.selected.flag = null;
            } else {
                if (!state.countries.values) return;
                let option_index = state.countries.values.findIndex(opt => opt === meta.market);
                state.selected.market = meta.market;
                state.selected.flag = meta.market;
                state.selected.country = meta.country || state.countries.options[option_index];
            }
            if ('location' in meta) router.push(meta.location);
        },
        update_app_size: (state, size) => {
            state.global.window_height = size.height;
            state.global.window_width = size.width;
        },
        update_market: (state, market) => {
            if (!state.countries.values) return;
            if (market && market !== 'null') {
                let option_index = state.countries.values.findIndex(opt => opt === market);
                state.selected.market = market;
                state.selected.flag = market;
                state.selected.country = state.countries.options[option_index];
            } else if (localStorage['saved-countries']) {
                const stored_market = JSON.parse(localStorage['saved-countries']);
                state.selected.market = stored_market.market;
                state.selected.flag = stored_market.market;
                state.selected.country = stored_market.caption;
                return;
            } else {
                state.selected.market = null;
                state.selected.flag = null;
                state.selected.country = 'Total Retail Countries';
            }
        },
        update_view: (state, meta) => {
            const serialise = () => {
                if (meta.market !== router.app.$route.params.market) return '';
                const query = router.app.$route.query;
                let q = '';
                for (let k in query) {
                    if (q === '') q += '?';
                    else q += '&';
                    q += `${k}=${query[k]}`;
                }
                return q;
            }
            if (meta.market) {
                if (!state.countries.values) return;

                let option_index = state.countries.values.findIndex(opt => opt === meta.market);
                state.selected.market = meta.market;
                state.selected.flag = meta.market;

                state.selected.country = state.countries.options[option_index];
                if (!state.global.can_route) return;
                
                if (meta.market === 'total-ingka-by-country') {
                    router.push(meta.location + serialise());
                } else if ('option' in meta && meta.option !== undefined && meta.option !== null && meta.option !== false) {
                    router.replace("../");
                    router.push(`${meta.market}/${meta.option}${serialise()}`);
                } else {
                    router.push(`${meta.location}/${meta.market}${serialise()}`);
                }
                return;
            }

            state.selected.country = 'Total Retail Countries';
            state.selected.market = null;
            state.selected.flag = null;

            if (!state.global.can_route) return;

            if ('option' in meta && meta.option !== undefined && meta.option !== null && meta.option !== false) {
                router.replace("../");
                router.push(`${meta.market}/${meta.option}${serialise()}`);
            } else {
                if (meta.page=='marketareaview' && meta.market==null) 
                    router.push(`${meta.location}/${serialise()}`);
                else
                    router.push(`${meta.location}/null${serialise()}`);
            }
        }
    },
    actions: {
        get_countries: ({ commit }) => {
            Vue.prototype.$http.get('/countries').then(
                succ => {
                    let data = succ.data;
                    let values = ['null'], options = [];

                    data = data.sort((a, b) => a.caption - b.caption);

                    data.map(item => {
                        values.push(item.market);
                        options.push(item.caption);
                    });

                    commit('set_countries', {
                        all: succ.data.sort((a, b) => a.caption > b.caption ? 1 : -1),
                        options: ['Total Retail Countries', ...options],
                        values: values
                    });
                },
                Vue.prototype.$helper.error
            );
        },
        get_hfbs: ({ commit }) => {
            Vue.prototype.$http.get('/hfb/get').then(
                succ => {
                    let data = succ.data;
                    let options = [], values = [];
                    data.map(item => {
                        values.push(item.hfb_no)
                        options.push(item.hfb_cap)
                    });

                    commit('set_hfbs', {
                        all: succ.data,
                        options: options,
                        values: values
                    });
                },
                Vue.prototype.$helper.error
            );
        },
        get_stores: ({ commit }) => {
            Vue.prototype.$http.get('/stores/get').then(
                succ => {
                    let data = succ.data;
                    let parent = [], market = [], caption = [];
                    data.map(item => {
                        parent.push(item.filter);
                        market.push(item.value);
                        caption.push(item.option);
                    });
                    commit('set_stores', {
                        all: succ.data,
                        parent: parent,
                        market: market,
                        caption: caption
                    });
                },
                Vue.prototype.$helper.error
            );
        },
    },
    getters: {
        access_template_name: state => state.access.access_template_name,
        access_super_user: state => state.access.access_super_user,
        access_request_show: state => state.access.show_access_request_modal,
        check_access: state => path => {
            let access = state.access.access_template;
            if (!access) return;
            path.forEach(p => access = access && p in access ? access[p] : false);
            return access;
        },
        countries: state => state.countries,
        // Returns first item in filter set if temp filter can't be found
        filter_check: state => filter_set => filter_set.indexOf(state.global.temp_filter) > 0 ? state.global.temp_filter : filter_set[0],
        hfbs: state => state.hfbs,
        location: state => {
            let route = state.global.route;
            let location_keys = ['kpi', 'kpi_area', 'market_area', 'store_area'];
            let location = {};
            if (route[0] === '/') {
                route = route.slice(1, route.length);
            }
            route = route.split('/');

            if (route.length > 4) {
                for (let i = 4; i < route.length; i++) {
                    route[3] = route[3] + `/${route[i]}`
                };
            }

            location_keys.map((loc, index) => {
                location[loc] = route[index] ? route[index] : null;
            });
            return location;
        },
        market: state => state.selected.market,
        flag: state => state.selected.flag,
        page_size: state => state.global.window_height + state.global.window_width,
        presentation_mode: state => state.global.presentation_mode,
        selected_country_name: state => state.selected.country,
        stores: state => {
            let options = ['Total Country']
            let values = [null];

            if (state.selected.market != null) {
                state.stores.all.forEach(store => {
                    if (store.filter === state.selected.market) {
                        values.push(store.value);
                        options.push(store.option);
                    }
                });
            }
            return {
                options,
                values
            };
        }
    }
});
