<template>
    <div class="kpi-box">
      <div class="body">
        <table class="table is-striped is-fullwidth is-narrow">
          <thead>
            <tr class="grey-background">
              <th></th>
              <th class="header">Week</th>
              <th>4 Week</th>
              <th class="header">YTD</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="sub-header">Index</td>
              <td class="data-column text-left">
                <div class="inline-flex"><div>{{ data.actuals_index }}</div> </div>
              </td>
              <td class="data-column text-left">
                <div class="inline-flex"><div>{{ data.r4_index }}</div>
                 <!-- <div><span class="icon has-text-danger ft-tooltip" tooltiptext="Compares to YTD Goal">
                    <i class="fas fa-circle"></i> </span>
                 </div> -->
                </div>
              </td>
              <td class="data-column text-left">
                <div class="inline-flex bold_ytd_value"><div>{{ data.ytd_index }}</div> </div>
              </td>
            </tr>
            <tr>
              <td class="sub-header">Actual</td>
              <td class="data-column text-left">
                <div class="inline-flex"><div>{{ data.actuals }}</div> </div>
              </td>
              <td class="data-column text-left">
                <div class="inline-flex"><div>{{ data.actuals_r4 }}</div> </div>
              </td>
              <td class="data-column text-left">
                <div class="inline-flex bold_ytd_value"><div>{{ data.actuals_fytd }}</div> </div>
              </td>
            </tr>
            <tr>
              <td class="sub-header">Goal</td>
              <td class="data-column text-left">
                <div class="inline-flex"><div>{{ data.goals }}</div> </div>
              </td>
              <td class="data-column text-left">
                <div class="inline-flex"><div>{{ data.goals_r4 }}</div> </div>
              </td>
              <td class="data-column text-left">
                <div class="inline-flex bold_ytd_value"><div>{{ data.goals_fytd }}</div> </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'GlobalBox',
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  
  .data-column {
      text-align: right;
  }
  
  .header {
      text-align: center !important;
      border-left: 1px solid white;
  }
  
  .kpi-box {
      background-color: #FFF;
      height: 100%;
      overflow: hidden;
      position: relative;
      z-index: 1;
  
      .body {
          background-color: #FFF;
      }
  }
  
  .kpi-box .table th {
      border-bottom: 2px solid #CCE3FF;
      font-weight: bold;
      white-space: nowrap;
      text-align: center;
  }
  
  .kpi-box .table tr td {
      font-weight: 400;
      text-align: center;
  }
  
  .meta-desc {
      font-size: 0.8rem;
      padding-left: 0.5rem;
      text-align: left;
  }
  
  .name-column {
      width: 42%;
  }
  
  .set-width {
      width: 4em;
  }
  
  .sub-header {
      font-size: 0.9rem;
      font-weight: bold !important;
      text-align: left !important;
  }
  
  .grey-background {
      font-size: 0.8rem;
      background-color: #e3e3e1;
  }
  
  table.table thead tr th {
      color: black !important;
  }

  .kpi-box .table tr .bold_ytd_value {
      font-weight: 600;
      font-size: 1em;
  }

  
  </style>
  